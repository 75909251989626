import { Controller } from '@hotwired/stimulus';

export default class CharacterCounter extends Controller<HTMLElement> {
  private declare readonly counterTarget: HTMLElement;
  private declare readonly remainingTarget: HTMLElement;
  private declare readonly inputTarget: HTMLInputElement;

  public static targets = ['input', 'counter', 'remaining'];

  public initialize(): void {
    this._update = this._update.bind(this);
  }

  public connect(): void {
    this._update();
    this.inputTarget.addEventListener('input', this._update.bind(this));
    this.inputTarget.addEventListener('trix-change', this._update.bind(this));
  }

  public disconnect(): void {
    this.inputTarget.removeEventListener('input', this._update.bind(this));
    this.inputTarget.removeEventListener(
      'trix-change',
      this._update.bind(this)
    );
  }

  private _update(): void {
    const maxLength = this.inputTarget.getAttribute('maxlength');

    if (maxLength === null) {
      throw Error('The maxlength attribute is missing');
    }

    const limit = parseInt(maxLength);

    this.counterTarget.innerHTML = this._count.toString();
    this.remainingTarget.innerHTML = (limit - this._count).toString();
  }

  private get _count(): number {
    return this.inputTarget.value.length;
  }
}
