import React, { useState } from 'react';
import CustomSelect from '../CustomSelect';

type SelectOptionsWithFte = Array<{
  full_time_equivalent: boolean;
  label: string;
  value: string;
}>;

interface JobTypeProps {
  readonly formName: string;
  readonly formId: string;
  readonly jobTypeValue?: string;
  readonly showHoursField: boolean;
  readonly jobTypeOptions: SelectOptionsWithFte;
  readonly jobTypeFieldHasError: boolean;
  readonly jobTypeError: string;
  readonly hoursPerWeekFixed: boolean;
  readonly hoursPerWeek?: number;
  readonly hoursPerWeekMin?: number;
  readonly hoursPerWeekMax?: number;
  readonly hoursPerWeekMaxFieldHasError: boolean;
  readonly hoursPerWeekMaxError: string;
  readonly jobTypeFieldPlaceholder: string;
}

const JobType: React.FC<JobTypeProps> = ({
  formName,
  formId,
  jobTypeValue,
  showHoursField,
  jobTypeOptions,
  jobTypeFieldHasError,
  hoursPerWeekFixed,
  hoursPerWeek,
  hoursPerWeekMin,
  hoursPerWeekMax,
  jobTypeError,
  hoursPerWeekMaxFieldHasError,
  hoursPerWeekMaxError,
  jobTypeFieldPlaceholder
}) => {
  const [hoursFieldVisible, setHoursFieldVisible] = useState(showHoursField);
  const [currentHoursPerWeekFixed, setCurrentHoursPerWeekFixed] =
    useState(hoursPerWeekFixed);

  const onJobTypeChange = (selected: {
    label: string;
    value: number | string;
  }) => {
    const jobType = jobTypeOptions.find(jt => jt.value === selected.value);

    setHoursFieldVisible(!jobType?.full_time_equivalent);
  };

  const onHoursFixedChange = (value: string) => {
    setCurrentHoursPerWeekFixed(value === '1');
  };

  return (
    <>
      <div className="mb-3">
        <CustomSelect
          label="Job Type"
          options={jobTypeOptions}
          id={`${formId}_jobTypeId`}
          hasError={jobTypeFieldHasError}
          name={`${formName}[job_type_id]`}
          placeholder={jobTypeFieldPlaceholder}
          onChange={selected => onJobTypeChange(selected)}
          initialValue={jobTypeValue}
          required
        />
        {jobTypeFieldHasError ? <span className="is-invalid" /> : null}
        {jobTypeError && (
          <div className="invalid-feedback d-block">{jobTypeError}</div>
        )}
      </div>
      {!hoursFieldVisible ? (
        <input
          type="hidden"
          id={`${formId}_hoursRange`}
          value={0}
          name={`${formId}[hours_fixed]`}
          checked
        />
      ) : (
        <div className="my-3 my-sm-5 panel rounded border-1 py-4 px-3 p-sm-4">
          <span className="form-label d-block pb-2 required">
            How many hours per week is this role?
          </span>
          <div className="panel rounded border-1 py-4 px-3 p-sm-4 bg-light mb-0">
            <input
              type="radio"
              id={`${formId}_hoursFixed`}
              value={1}
              name={`${formId}[hours_fixed]`}
              checked={currentHoursPerWeekFixed}
              onChange={event => onHoursFixedChange(event.target.value)}
              className="form-check-input"
            />
            <label
              htmlFor={`${formId}_hoursFixed`}
              className="form-check-label ms-3 mb-2"
            >
              Fixed
            </label>
            <fieldset
              disabled={!currentHoursPerWeekFixed}
              className="ms-4 ps-3 mb-2"
            >
              <input
                className="form-control w-auto d-inline"
                id={`${formId}_hoursPerWeek`}
                type="number"
                name={`${formName}[hours_per_week]`}
                min={1}
                max={100}
                value={hoursPerWeek ?? 0}
              />
              <label htmlFor={`${formId}_hoursPerWeek`} className="ms-2">
                hours per week
              </label>
            </fieldset>
            <input
              type="radio"
              id={`${formId}_hoursRange`}
              value={0}
              name={`${formId}[hours_fixed]`}
              checked={!currentHoursPerWeekFixed}
              onChange={event => onHoursFixedChange(event.target.value)}
              className="form-check-input"
            />
            <label
              htmlFor={`${formId}_hoursRange`}
              className="form-check-label ms-3 mb-2"
            >
              Range
            </label>
            <fieldset disabled={currentHoursPerWeekFixed} className="ms-4 ps-3">
              <input
                className="form-control w-auto d-inline mb-2"
                id={`${formId}_hoursPerWeekMin`}
                type="number"
                name={`${formName}[hours_per_week_min]`}
                min={1}
                max={100}
                value={hoursPerWeekMin ?? 0}
              />
              <span className="mx-2">to</span>
              <input
                className="form-control w-auto d-inline"
                id={`${formId}_hoursPerWeekMax`}
                type="number"
                name={`${formName}[hours_per_week_max]`}
                min={1}
                max={100}
                value={hoursPerWeekMax ?? 0}
              />
              <label htmlFor={`${formId}_hoursPerMax`} className="ms-2">
                hours per week
              </label>
              {hoursPerWeekMaxFieldHasError ? (
                <span className="is-invalid" />
              ) : null}
              {hoursPerWeekMaxError && (
                <div className="invalid-feedback d-block">
                  {hoursPerWeekMaxError}
                </div>
              )}
            </fieldset>
          </div>
        </div>
      )}
    </>
  );
};

export default JobType;
