import { ContentfulClientApi, createClient } from 'contentful';

declare const process: {
  env: {
    CONTENTFUL_SPACE_ID: string;
    CONTENTFUL_DELIVERY_ACCESS_TOKEN: string;
  };
};

const buildClient = (): ContentfulClientApi =>
  createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN,
    removeUnresolved: true
  });

export default buildClient;
