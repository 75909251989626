import { ComponentType } from 'preact';
import {
  GroupBase,
  MultiValueProps,
  OptionProps,
  Props,
  components
} from 'react-select';

const childOptionStyle = {
  marginLeft: '2rem'
};

const selectAllStyle = {
  margin: '0.5rem 0 0.5rem 2rem'
};

const ChildOption = (props: {
  readonly data: NestedMultiSelectOptionInterface;
  readonly selectedValues: NestedMultiSelectOptionInterface[];
  readonly onClick: (e: React.FormEvent<HTMLLabelElement>) => void;
}) => {
  const { data, selectedValues, onClick } = props;

  const { label, value } = data;

  const checked = !!selectedValues.find(sel => {
    return sel.value === value;
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      style={childOptionStyle}
      key={label}
      onClick={onClick}
      onKeyUp={onClick}
    >
      <input
        id={label}
        type="checkbox"
        value={value}
        className="form-check-input"
        checked={checked}
      />
      &nbsp;{label}
    </label>
  );
};

const ParentOption = ({
  checked,
  label,
  value,
  onClick,
  alt
}: {
  readonly alt: string;
  readonly value: string;
  readonly checked: boolean;
  readonly label: string;
  readonly onClick: (e: React.FormEvent<HTMLLabelElement>) => void;
}) => {
  return (
    /* click handler should be on the input as indicated here:
          https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/672

          however in this case this causes a bug where clicks on the label close the menu
          and do not select the target, and therefore the label must have a click handler
          https://github.com/JedWatson/react-select/issues/4666 */
    /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
    <label onClick={onClick} onKeyUp={onClick}>
      <input
        id={label}
        type="checkbox"
        checked={checked}
        value={value}
        className="form-check-input"
        alt={alt}
      />
      &nbsp;{label}
    </label>
  );
};

export interface CustomSelectProps
  extends Props<
    NestedMultiSelectOptionInterface,
    true,
    GroupBase<NestedMultiSelectOptionInterface>
  > {
  id: string;
  normalizedOptions: NestedMultiSelectOptionInterface[];
  selectedValues: NestedMultiSelectOptionInterface[];
  setSelectedValues: (newValue: NestedMultiSelectOptionInterface[]) => void;
}

// Interface 'CustomOptionProps' incorrectly extends interface
// 'OptionProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>'.
//   The types of 'selectProps.backspaceRemovesValue' are incompatible between these types.
//     Type 'boolean | undefined' is not assignable to type 'boolean'.
//       Type 'undefined' is not assignable to type 'boolean'.ts(2430)
// @ts-expect-error TS have tried to unpick the type web unsuccessfully
interface CustomOptionProps
  extends OptionProps<NestedMultiSelectOptionInterface, true> {
  selectProps: CustomSelectProps;
}

const OptionComponent = (
  props: CustomOptionProps
):
  | ComponentType<
      MultiValueProps<
        NestedMultiSelectOptionInterface,
        true,
        GroupBase<NestedMultiSelectOptionInterface>
      >
    >
  | undefined => {
  const { data, selectOption, selectProps } = props;
  const { parentId, value, label } = data;
  const isParent = parentId === null;
  const { normalizedOptions, selectedValues, setSelectedValues } = selectProps;

  const parent = isParent
    ? data
    : normalizedOptions.find(opt => opt.value === data.parentId);

  const parentSelected = !!selectedValues.find(
    sel => sel.value === parent?.value
  );

  const childSelected = !!selectedValues.find(
    sel => sel.parentId === (isParent ? data.value : data.parentId)
  );

  const onParentClick = (e: React.FormEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (childSelected) {
      setSelectedValues([
        ...selectedValues.filter(sel => {
          // Remove any children of the parent from the array of selectedValues
          return sel.parentId !== parent?.value;
        })
      ]);
    } else {
      selectOption({ ...data });
    }
  };

  const onSelectAllClick = (e: React.FormEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    e.preventDefault();

    selectOption({ ...data });
  };

  const ChildComponent =
    parentSelected || childSelected ? (
      // Type '{ children: Element; selectProps: CustomSelectProps; innerRef: (instance: HTMLDivElement | null) => void; innerProps: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>; ... 17 more ...; theme: Theme; }' is not assignable to type 'OptionProps<NestedMultiSelectOptionInterface, boolean, GroupBase<NestedMultiSelectOptionInterface>>'.
      // The types of 'selectProps.backspaceRemovesValue' are incompatible between these types.
      //   Type 'boolean | undefined' is not assignable to type 'boolean'.
      //     Type 'undefined' is not assignable to type 'boolean'.
      // @ts-expect-error TS have tried to unpick the type web unsuccessfully
      <components.Option {...props}>
        <ChildOption
          data={data}
          onClick={onSelectAllClick}
          selectedValues={selectedValues}
        />
      </components.Option>
    ) : undefined;

  const OptionToRender = isParent ? (
    // Type '{ children: Element; selectProps: CustomSelectProps; innerRef: (instance: HTMLDivElement | null) => void; innerProps: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>; ... 17 more ...; theme: Theme; }' is not assignable to type 'OptionProps<NestedMultiSelectOptionInterface, boolean, GroupBase<NestedMultiSelectOptionInterface>>'.
    // The types of 'selectProps.backspaceRemovesValue' are incompatible between these types.
    //   Type 'boolean | undefined' is not assignable to type 'boolean'.
    //     Type 'undefined' is not assignable to type 'boolean'.
    // @ts-expect-error TS have tried to unpick the type web unsuccessfully
    <components.Option {...props}>
      <ParentOption
        label={label}
        value={value}
        checked={parentSelected || childSelected}
        onClick={onParentClick}
        alt={`${label}`}
      />
      {(parentSelected || childSelected) && (
        <>
          <div style={selectAllStyle}>
            <ParentOption
              onClick={onSelectAllClick}
              value={value}
              label="All Subcategories"
              checked={parentSelected && !childSelected}
              alt={`All Subcategories of ${label}`}
            />
          </div>
          <hr className="my-0 mx-4" />
        </>
      )}
    </components.Option>
  ) : (
    ChildComponent
  );

  // Type 'Element | undefined' is not assignable to type 'ComponentType<MultiValueProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>> | undefined'.
  // @ts-expect-error TS have tried to unpick the type web unsuccessfully
  return OptionToRender;
};

export default OptionComponent;
