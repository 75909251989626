/* eslint-disable no-new */
import Carousel from 'bootstrap/js/dist/carousel';
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Tab from 'bootstrap/js/dist/tab';
import Tooltip from 'bootstrap/js/dist/tooltip';

function initializeTooltips() {
  const tooltipTriggerList = Array.from(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );

  tooltipTriggerList.forEach(tooltipTriggerEl => {
    new Tooltip(tooltipTriggerEl);
  });
}

function initializeDropdowns() {
  const dropdownList = Array.from(document.querySelectorAll('.dropdown'));

  dropdownList.forEach(dropdownEl => {
    new Dropdown(dropdownEl);
  });
}

function initializeCarousels() {
  const carouselList = Array.from(document.querySelectorAll('.carousel'));

  carouselList.forEach(carouselEl => {
    new Carousel(carouselEl);
  });
}

function initializeTabs() {
  const tabList = Array.from(
    document.querySelectorAll('[data-bs-toggle="tab"]')
  );

  tabList.forEach(tabEl => {
    new Tab(tabEl);
  });
}

function initializeCollapse() {
  const collapseElementList = Array.from(
    document.querySelectorAll('.collapse')
  );

  collapseElementList.map(
    collapseEl => new Collapse(collapseEl, { toggle: false })
  );
}

['DOMContentLoaded', 'turbo:render', 'turbo:frame-render'].forEach(evt =>
  document.addEventListener(evt, () => {
    initializeCarousels();
    initializeCollapse();
    initializeTooltips();
    initializeDropdowns();
    initializeTabs();
  })
);
