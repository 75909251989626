import React, { useMemo, useState } from 'react';
import CustomSelect from './CustomSelect';

const styles = {
  placeholder: (base: object) => ({
    ...base,
    fontWeight: 'normal'
  }),
  valueContainer: (base: object) => ({
    ...base,
    padding: '0.25rem 0.5rem'
  })
};

interface SalaryRangeProps {
  readonly salaryRangeType: string;
  readonly options: SalaryRangeInterface[];
  readonly salaryBandFromId: string | null;
  readonly salaryBandToId: string | null;
  readonly menuIsOpen?: boolean;
}

const SalaryRange: React.FC<SalaryRangeProps> = ({
  menuIsOpen,
  salaryRangeType,
  salaryBandFromId,
  salaryBandToId,
  options
}) => {
  const annualOptions = useMemo(
    () => options.filter(opt => opt.remuneration_period === 'annually'),
    [options]
  );

  const hourlyOptions = useMemo(
    () => options.filter(opt => opt.remuneration_period === 'hourly'),
    [options]
  );

  const [currentSalaryRangeType, setCurrentSalaryRangeType] =
    useState(salaryRangeType);

  const currentOptions =
    currentSalaryRangeType === 'hourly' ? hourlyOptions : annualOptions;

  const fromOptions = useMemo(
    () =>
      currentOptions.map(opt => {
        return {
          label: opt.display_value,
          value: opt.id
        };
      }),
    [currentOptions]
  );

  const toOptions = useMemo(
    () =>
      currentOptions.map(opt => {
        return {
          label: opt.display_value,
          value: opt.id
        };
      }),
    [currentOptions]
  );

  const fromInitialOption = fromOptions.find(
    opt =>
      salaryBandFromId && opt.value.toString() === salaryBandFromId.toString()
  );

  const toInitialOption = toOptions.find(
    opt => salaryBandToId && opt.value.toString() === salaryBandToId.toString()
  );

  const [salaryFrom, setSalaryFrom] = useState(fromInitialOption ?? '');
  const [salaryTo, setSalaryTo] = useState(toInitialOption ?? '');

  /*
    Having tried other approaches like functional composition and switch statements
    This is a rare case where nested ternary is actually the most legible way of writing this

    The logic builds the placeholder based on what values are present
    If both values are set, use those.
    If only one value is set, use the string 'Any' for the other value
    If neither value is set, fall back a default
  */
  const placeholder =
    /* eslint-disable no-nested-ternary */
    typeof salaryFrom === 'object' && typeof salaryTo === 'object'
      ? `${salaryFrom.label} to ${salaryTo.label}`
      : typeof salaryFrom === 'object'
        ? `${salaryFrom.label} to any`
        : typeof salaryTo === 'object'
          ? `any to ${salaryTo.label}`
          : 'Any Pay Range';
  /* eslint-enable no-nested-ternary */

  const onSalaryRangeTypeChange = (value: string) => {
    setCurrentSalaryRangeType(value);
    setSalaryFrom('');
    setSalaryTo('');
  };

  // due to using min and max values
  // in addition to doing a numeric comparsion
  // this also compares labels to filter out options where
  // the value is greater but the label is the same
  const optionIsDisabled = (option: { label: string; value: string }) => {
    if (typeof salaryFrom !== 'object') {
      return false;
    }

    const salaryBandFrom = currentOptions.find(
      ({ id }) => id.toString() === salaryFrom.value
    );
    const salaryBandTo = currentOptions.find(
      ({ id }) => id.toString() === option.value
    );

    return !!(
      salaryBandFrom &&
      salaryBandTo &&
      (Number(salaryBandTo.minimum_salary) <=
        Number(salaryBandFrom.minimum_salary) ||
        option.label === salaryFrom.label)
    );
  };

  return (
    <div className="btn-group w-100">
      <button
        className="form-select border-0 text-start"
        style={{
          // this needs to be exact to match other inputs and we don't have
          // any utility classes able to achieve this sizing
          padding: '0.75rem 1rem'
        }}
        type="button"
        id="dropdownMenuClickableInside"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
      >
        {placeholder}
      </button>
      <div
        className="dropdown-menu mt-2 w-100"
        aria-labelledby="dropdownMenuClickableInside"
        style={{ minWidth: '300px' }}
      >
        <div className="d-flex flex-column p-3 py-3">
          <div className="d-flex py-2">
            <div className="btn-group mb-2 w-100">
              <label
                htmlFor="SalaryRangeTypeSalary"
                className={`py-2 px-3 flex-grow-1 btn btn-sm ${
                  currentSalaryRangeType !== 'hourly'
                    ? 'btn-primary'
                    : 'btn-outline-primary'
                }`}
              >
                Annually
              </label>
              <label
                htmlFor="SalaryRangeTypeHourly"
                className={`py-2 px-3 flex-grow-1 btn btn-sm ${
                  currentSalaryRangeType === 'hourly'
                    ? 'btn-primary'
                    : 'btn-outline-primary'
                }`}
              >
                Hourly
              </label>
            </div>
            <input
              type="radio"
              id="SalaryRangeTypeSalary"
              value="annually"
              name="salary_range_type"
              checked={currentSalaryRangeType !== 'hourly'}
              onChange={event => onSalaryRangeTypeChange(event.target.value)}
              className="visually-hidden"
            />
            <input
              type="radio"
              id="SalaryRangeTypeHourly"
              value="hourly"
              name="salary_range_type"
              checked={currentSalaryRangeType === 'hourly'}
              onChange={event => onSalaryRangeTypeChange(event.target.value)}
              className="visually-hidden"
            />
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-6 pe-1">
              <CustomSelect
                className="border-primary"
                controlledValue={salaryFrom}
                id="salary_from"
                isSearchable={false}
                label="From"
                labelClassName="fs-6 fw-normal"
                menuIsOpen={menuIsOpen}
                name="salary_band_from_id"
                onChange={opt => setSalaryFrom(opt)}
                options={fromOptions}
                placeholder="Any"
                styles={styles}
              />
            </div>

            <div className="col-6 ps-1">
              <CustomSelect
                className="border-primary"
                controlledValue={salaryTo}
                id="salary_to"
                isSearchable={false}
                label="To"
                labelClassName="fs-6 fw-normal"
                menuIsOpen={menuIsOpen}
                name="salary_band_to_id"
                onChange={opt => setSalaryTo(opt)}
                options={toOptions}
                placeholder="Any"
                styles={styles}
                isOptionDisabled={optionIsDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryRange;
