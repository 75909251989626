import React from 'preact/compat';
import Markdown from 'react-markdown';
import RemarkBreaks from 'remark-breaks';
import GfmMarkdown from 'remark-gfm';
import useContentful from '../../js/contentful/useContentful';

interface EntryWithContent {
  content: string;
}

const MarkdownContent: React.FC<{
  contentType: string;
  query: Record<string, string>;
}> = ({ contentType, query }) => {
  const { data } = useContentful<EntryWithContent>({
    contentType,
    query: {
      order: 'sys.createdAt',
      limit: 1,
      ...query
    }
  });

  const content = data?.items[0];

  return content ? (
    <Markdown remarkPlugins={[RemarkBreaks, GfmMarkdown]} linkTarget="_blank">
      {content.fields.content}
    </Markdown>
  ) : null;
};

export default MarkdownContent;
