import { Controller } from '@hotwired/stimulus';
import ReactRailsUJS from 'react_ujs';

// We need to mount React components after a turbo-streams response. Turbo
// doesn't have a built-in 'after-stream-render' event so we implement this
// functionality by attaching a `data-controller="react-mounter"` attribute to
// whatever DOM node contains the react components we need to re-mount. This
// stimulus controller will attempt to mount any react components within it's
// DOM node when it notices that the DOM node changes.

export default class ReactMounterController extends Controller<HTMLElement> {
  public connect(): void {
    ReactRailsUJS.mountComponents(this.element);
  }
}
