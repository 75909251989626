import { Controller } from '@hotwired/stimulus';
import { TrixEditor, TrixEditorElement } from './trix_editor_controller';

export default class TrixEditorInterpolateController extends Controller<TrixEditorElement> {
  private toolbar!: HTMLElement;
  private interpolationControl!: HTMLSelectElement;
  private editor!: TrixEditor;
  private interpolations!: Record<string, string>;

  public connect(): void {
    this.interpolations = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.element.dataset.interpolations!
    ) as Record<string, string>;

    this.element.addEventListener('trix-initialize', () => {
      this.toolbar = this.element.toolbarElement;
      this.editor = this.element.editor;
      this.interpolationControl = this._buildInterpolationControl();
      this._addInterpolationControl();
      this.interpolationControl.addEventListener('change', () =>
        this._interpolate()
      );
    });
  }

  public disconnect(): void {
    this.interpolationControl.removeEventListener('change', () =>
      this._interpolate()
    );
  }

  private _interpolate(): void {
    // Can't destructure an HTMLElementCollection
    // eslint-disable-next-line prefer-destructuring
    const selectedOption = this.interpolationControl.selectedOptions[0];

    this.element.editor.recordUndoEntry(`Insert ${selectedOption.innerText}`);
    this.editor.insertString(selectedOption.value);
    this.interpolationControl.value = '';
  }

  private _addInterpolationControl(): void {
    const toolsElement = this.toolbar.querySelector(
      '.trix-button-group--block-tools'
    );

    toolsElement?.insertBefore(
      this.interpolationControl,
      toolsElement.firstChild
    );
  }

  private _buildInterpolationControl(): HTMLSelectElement {
    const select = document.createElement('select');
    const insertOption = (label: string, value: string | null) => {
      const option = document.createElement('option');

      option.innerText = label;
      option.value = value ?? '';
      select.appendChild(option);

      return option;
    };

    select.dataset.testId = 'trix-editor-interpolate';
    select.className = 'form-select form-select-sm me-2';
    insertOption('Insert Reference', null).disabled = true;

    Object.keys(this.interpolations).forEach(interpolationLabel =>
      insertOption(interpolationLabel, this.interpolations[interpolationLabel])
    );

    return select;
  }
}
