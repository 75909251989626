import { Controller } from '@hotwired/stimulus';

export default class NestedFieldsController extends Controller {
  private declare readonly targetTarget: HTMLElement;
  private declare readonly templateTarget: HTMLElement;
  private declare readonly wrapperSelectorValue: string;

  public static targets = ['target', 'template'];
  public static values = {
    wrapperSelector: {
      type: String,
      default: '.nested-fields-wrapper'
    }
  };

  public add(e: Event): void {
    e.preventDefault();

    const content: string = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/gu,
      new Date().getTime().toString()
    );

    this.targetTarget.insertAdjacentHTML('beforebegin', content);
  }

  public remove(e: Event): void {
    e.preventDefault();

    const target = e.target as HTMLElement;
    const wrapper: HTMLElement | null = target.closest(
      this.wrapperSelectorValue
    );

    wrapper?.remove();
  }
}
