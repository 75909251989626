import { components } from 'react-select';
import ReactSelectInterface from 'react-select/dist/declarations/src/Select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';
import CustomSelect from '../CustomSelect';

const styles = {
  option: (base: object, data: { isFocused: boolean }): object => ({
    ...base,
    backgroundColor: data.isFocused ? '#DEEBFF' : 'transparent',
    color: '#1b2224'
  }),
  placeholder: (base: object) => ({
    ...base
  }),
  valueContainer: (base: object) => ({
    ...base,
    padding: '.5rem 1rem',
    display: 'flex'
  })
};

interface CheckboxMultiSelectProps {
  className?: string;
  closeMenuOnSelect?: boolean;
  customRef?: React.RefObject<ReactSelectInterface>;
  id: string;
  initialValue?: string[];
  isDisabled?: boolean;
  includePlaceholderOption?: boolean;
  hasError: boolean;
  label?: string;
  menuIsOpen?: boolean; // for testing
  name: string;
  options: CustomSelectOptions;
  onChange?: (
    newValue: { label: string; value: number | string },
    meta: { name: string; action: string }
  ) => void;
  placeholder?: string;
  showSearchComponent?: boolean;
}

const CheckboxMultiSelect: React.FC<CheckboxMultiSelectProps> = ({
  placeholder,
  className,
  closeMenuOnSelect = false,
  showSearchComponent = true,
  ...rest
}) => {
  const Option = (
    props: OptionProps<{ label: string; value: number | string }>
  ) => {
    const { isSelected, label, data, selectOption } = props;

    const onClickMultiOption = (e: React.FormEvent<HTMLLabelElement>) => {
      selectOption({ ...data });
      e.stopPropagation();
      e.preventDefault();
    };

    return (
      <div>
        <components.Option {...props}>
          <input
            id={label}
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
            className="form-check-input"
          />
          {/* click handler should be on the input as indicated here:
            https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/672

            however in this case this causes a bug where clicks on the label close the menu
            and do not select the target, and therefore the label must have a click handler
            https://github.com/JedWatson/react-select/issues/4666 */}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <label
            onClick={onClickMultiOption}
            onKeyUp={onClickMultiOption}
            htmlFor={label}
          >
            &nbsp;{label}
          </label>
        </components.Option>
      </div>
    );
  };

  const SearchValueContainer = (props: ValueContainerProps) => {
    const { children, getValue } = props;
    const currentValues = getValue();

    const prefix =
      currentValues.length > 0 ? `(${currentValues.length})` : 'All ';

    return (
      <components.ValueContainer {...props}>
        <span>
          {prefix}
          {placeholder}
        </span>
        {/* Second child in array is input we want to render but
         @ts-expect-error because typescript not acknowledging React.fragment is an array */}
        {children?.[1]}
      </components.ValueContainer>
    );
  };

  return (
    <CustomSelect
      className={`${className ?? ''}`}
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        Option,
        ...(showSearchComponent && { ValueContainer: SearchValueContainer })
      }}
      hideSelectedOptions={false}
      isMulti
      isClearable={false}
      placeholder={placeholder}
      styles={styles}
      {...rest}
    />
  );
};

export default CheckboxMultiSelect;
