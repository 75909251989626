import { Controller } from '@hotwired/stimulus';

export default class RequiredFieldsController extends Controller<HTMLInputElement> {
  private requiredFields: HTMLInputElement[] = [];

  public connect(): void {
    this.element.disabled = true;

    if (this.element.form) {
      this.requiredFields = Array.from(
        this.element.form.querySelectorAll<HTMLInputElement>('[required]')
      );
      this.requiredFields.map(input => {
        input.addEventListener('keyup', this._checkFormValidity);
        input.addEventListener('blur', this._checkFormValidity);
        input.addEventListener('change', this._checkFormValidity);
      });

      this._checkFormValidity();
    }
  }

  public disconnect(): void {
    if (this.element.form) {
      this.requiredFields.map(input => {
        input.removeEventListener('keyup', this._checkFormValidity);
        input.removeEventListener('blur', this._checkFormValidity);
        input.removeEventListener('change', this._checkFormValidity);
      });
    }
  }

  private readonly _checkFormValidity = (): void => {
    const formIsValid = this.element.form?.checkValidity();
    const elementsAreValid =
      this.requiredFields.filter(input =>
        // Disable this check because the input may not _necessarily_ be an input.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        input.checkValidity ? input.checkValidity() : true
      ).length === this.requiredFields.length;

    this.element.disabled = !(formIsValid && elementsAreValid);
  };
}
