// The application.js pack is defered by default which means that nothing imported
// in this file will begin executing until after the page has loaded. This helps to
// speed up page loading times, especially in apps that have large amounts of js.
//
// If you have javascript that *must* execute before the page has finished loading,
// create a separate 'boot.js' pack in the frontend/packs directory and import any
// required files in that. Also remember to add a separate pack_tag entry with:
// <%= javascript_pack_tag "boot", "data-turbolinks-track": "reload" %>
// to the views/layouts/application.html.erb file above the existing application pack tag.
//
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Application } from '@hotwired/stimulus';
import * as ActiveStorage from '@rails/activestorage';
import * as Sentry from '@sentry/browser';
import ReactRailsUJS from 'react_ujs';
import '../js/bootstrap.ts';
// eslint-disable-next-line import/order
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

const application = Application.start();

import '../stylesheets/application.scss';
import 'channels';
import '@hotwired/turbo-rails';
import 'trix';
import '@rails/actiontext';

ActiveStorage.start();

require.context('../images', true);

// A note about standalone images
// If you need them to be compiled, you MUST require them here.
// image_pack_tag, etc does this for you, but NOT if you are wanting to use
// something standalone (like asset_url)
import '../images/opengraph_metadata_image.png';
import '../images/twitter_metadata_image.png';

// Initialize Sentry Error Reporting
//
// Import all your application's JS after this section because we need Sentry
// to be initialized **before** we import any of our actual JS so that Sentry
// can report errors from it.
//
Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENV ?? process.env.RAILS_ENV
});

// Uncomment this Sentry by sending an exception every time the page loads.
// Obviously this is a very noisy activity and we do have limits on Sentry so
// you should never do this on a deployed environment.
//
// Sentry.captureException(new Error('Away-team to Enterprise, two to beam directly to sick bay...'));

// Import all your application's JS here
const context = require.context('controllers', true, /.[js|ts]$/u);

application.load(definitionsFromContext(context));

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);

// Automatically mount React components when Turbo renders.
// This can be from two different events:
//
// turbo:render: fires after Turbo renders the page. This event fires twice
//               during an application visit to a cached location: once after
//               rendering the cached version, and again after rendering the fresh version.
//
// turbo:frame-render: fires right after <turbo-frame> element renders its view.
//                     The specific <turbo-frame> element is the event target.
//                     Access the FetchResponse object with event.detail.fetchResponse property.
//
// We need to listen for both events since :render is primarily triggered by page navigations,
// and :render-frame is triggered by turbo-frame events, often form submissions.
//
// CAUTION: Listening to these events doesn't handle all the cases. In particular,
// adding DOM nodes still requires a component mount.
['turbo:render', 'turbo:frame-render'].forEach(eventName =>
  document.addEventListener(eventName, ({ target }) =>
    ReactRailsUJS.mountComponents((target ?? document.body) as HTMLElement)
  )
);

import pageLoadAnalyticsListner from '../js/analytics';
pageLoadAnalyticsListner();
