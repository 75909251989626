import React, { useState } from 'react';

interface RemunerationProps {
  readonly formName: string;
  readonly formId: string;
  readonly remunerationType: string;
  readonly salaryFixed: boolean;
  readonly salary: number;
  readonly salaryMin: number;
  readonly salaryMax: number;
  readonly salaryMaxFieldHasError: boolean;
  readonly salaryMaxFieldError: string[];
  readonly salaryMinFieldHasError: boolean;
  readonly salaryMinFieldError: string[];
  readonly salaryFieldHasError: boolean;
  readonly salaryFieldError: string[];
  readonly hourlyRateFixed: boolean;
  readonly hourlyRate: number;
  readonly hourlyRateMin: number;
  readonly hourlyRateMax: number;
  readonly hourlyRateMaxFieldHasError: boolean;
  readonly hourlyRateMaxFieldError: string[];
}

const Remuneration: React.FC<RemunerationProps> = ({
  formId,
  formName,
  remunerationType,
  salaryFixed,
  salary: originalSalary,
  salaryMin,
  salaryMax,
  hourlyRateFixed,
  hourlyRate,
  hourlyRateMin,
  hourlyRateMax,
  salaryMaxFieldHasError,
  salaryMaxFieldError,
  salaryMinFieldHasError,
  salaryMinFieldError,
  salaryFieldHasError,
  salaryFieldError,
  hourlyRateMaxFieldHasError,
  hourlyRateMaxFieldError
}) => {
  const [currentSalaryFixed, setCurrentSalaryFixed] = useState(salaryFixed);
  const [currentHourlyRateFixed, setCurrentHourlyRateFixed] =
    useState(hourlyRateFixed);
  const [currentRemunerationType, setCurrentRemunerationType] =
    useState(remunerationType);
  const [salary, setSalary] = useState(originalSalary || 0);
  const [minimumSalary, setMinimumSalary] = useState(salaryMin || 0);
  const [maximumSalary, setMaximumSalary] = useState(salaryMax || 0);

  const onSalaryFixedChange = (value: string) => {
    setCurrentSalaryFixed(value === '1');
  };

  const onHourlyRateFixedChange = (value: string) => {
    setCurrentHourlyRateFixed(value === '1');
  };

  const onRemunerationTypeChange = (value: string) => {
    setCurrentRemunerationType(value);
  };

  function getSalaryFields() {
    return (
      <div className="panel rounded border-1 py-4 px-3 p-sm-4 bg-light mb-0">
        <input
          type="radio"
          id={`${formId}_salaryFixed`}
          value={1}
          name={`${formId}[salary_fixed]`}
          checked={currentSalaryFixed}
          onChange={event => onSalaryFixedChange(event.target.value)}
          className="form-check-input"
        />
        <label
          htmlFor={`${formId}_salaryFixed`}
          className="form-check-label ms-3 mb-2"
        >
          Fixed
        </label>
        <fieldset disabled={!currentSalaryFixed} className="ms-4 ps-3 mb-2">
          <div className="input-group field-prefix">
            <span className="input-group-text">$</span>
            <input name={`${formName}[salary]`} type="hidden" value={salary} />
            <input
              className="form-control w-25 d-inline"
              id={`${formId}_salary`}
              type="number"
              min={0}
              step={1}
              onChange={event => setSalary(parseInt(event.target.value) * 1000)}
              value={salary / 1000}
            />
          </div>
          <label htmlFor={`${formId}_hoursPerWeek`} className="ms-2">
            <span className="d-inline-block pe-2">k</span>
            Annually
          </label>
          {salaryFieldHasError ? <span className="is-invalid" /> : null}
          {salaryFieldHasError ? (
            <div className="invalid-feedback d-block">
              {salaryFieldError.join(', ')}
            </div>
          ) : null}
        </fieldset>
        <input
          type="radio"
          id={`${formId}_salaryRange`}
          value={0}
          name={`${formId}[salary_fixed]`}
          checked={!currentSalaryFixed}
          onChange={event => onSalaryFixedChange(event.target.value)}
          className="form-check-input"
        />
        <label
          htmlFor={`${formId}_salaryRange`}
          className="form-check-label ms-3 mb-2"
        >
          Range
        </label>
        <fieldset disabled={currentSalaryFixed} className="ms-4 ps-3">
          <div className="input-group field-prefix me-1">
            <span className="input-group-text">$</span>
            <input
              name={`${formName}[salary_min]`}
              type="hidden"
              value={minimumSalary}
            />
            <input
              className="form-control w-25 d-inline"
              id={`${formId}_salaryMin`}
              aria-label="salary_min"
              type="number"
              min={0}
              step={1}
              onChange={event =>
                setMinimumSalary(parseInt(event.target.value) * 1000)
              }
              value={minimumSalary / 1000}
            />
          </div>
          <span className="d-inline-block ps-1 pe-2">k</span>
          to
          <div className="input-group field-prefix mx-2">
            <span className="input-group-text">$</span>
            <input
              name={`${formName}[salary_max]`}
              type="hidden"
              value={maximumSalary}
            />
            <input
              className="form-control w-25 d-inline"
              id={`${formId}_salaryMax`}
              aria-label="salary_max"
              type="number"
              min={0}
              step={1}
              onChange={event =>
                setMaximumSalary(parseInt(event.target.value) * 1000)
              }
              value={maximumSalary / 1000}
            />
          </div>
          <span className="d-inline-block pe-2">k</span>
          Annually
          {salaryMinFieldHasError ? <span className="is-invalid" /> : null}
          {salaryMinFieldHasError ? (
            <div className="invalid-feedback d-block">
              {salaryMinFieldError.join(', ')}
            </div>
          ) : null}
          {salaryMaxFieldHasError ? <span className="is-invalid" /> : null}
          {salaryMaxFieldHasError ? (
            <div className="invalid-feedback d-block">
              {salaryMaxFieldError.join(', ')}
            </div>
          ) : null}
        </fieldset>
      </div>
    );
  }

  function getHourlyRateFields() {
    return (
      <div className="panel rounded border-1 py-4 px-3 p-sm-4 bg-light mb-0">
        <input
          type="radio"
          id={`${formId}_hourlyRateFixed`}
          value={1}
          name={`${formId}[hourly_rate_fixed]`}
          checked={currentHourlyRateFixed}
          onChange={event => onHourlyRateFixedChange(event.target.value)}
          className="form-check-input"
        />
        <label
          htmlFor={`${formId}_hourlyRateFixed`}
          className="form-check-label ms-3 mb-2"
        >
          Fixed
        </label>
        <fieldset disabled={!currentHourlyRateFixed} className="ms-4 ps-3 mb-2">
          <div className="input-group field-prefix">
            <span className="input-group-text">$</span>
            <input
              className="form-control w-25 d-inline"
              id={`${formId}_hourlyRate`}
              type="number"
              name={`${formName}[hourly_rate]`}
              min={0}
              step={1}
              value={hourlyRate || 0}
            />
          </div>
          <label htmlFor={`${formId}_hourlyRate`} className="ms-2">
            per hour
          </label>
        </fieldset>
        <input
          type="radio"
          id={`${formId}_hourlyRateRange`}
          value={0}
          name={`${formId}[hourly_rate_fixed]`}
          checked={!currentHourlyRateFixed}
          onChange={event => onHourlyRateFixedChange(event.target.value)}
          className="form-check-input"
        />
        <label
          htmlFor={`${formId}_hourlyRateRange`}
          className="form-check-label ms-3 mb-2"
        >
          Range
        </label>
        <fieldset disabled={currentHourlyRateFixed} className="ms-4 ps-3">
          <div className="input-group field-prefix me-2">
            <span className="input-group-text">$</span>
            <input
              className="form-control w-25 d-inline"
              id={`${formId}_hourlyRateMin`}
              type="number"
              name={`${formName}[hourly_rate_min]`}
              min={0}
              step={1}
              value={hourlyRateMin || 0}
              aria-label="hourly_rate_min"
            />
          </div>
          to
          <div className="input-group field-prefix mx-2">
            <span className="input-group-text">$</span>
            <input
              className="form-control w-25 d-inline"
              id={`${formId}_hourlyRateMax`}
              type="number"
              name={`${formName}[hourly_rate_max]`}
              min={0}
              step={1}
              value={hourlyRateMax || 0}
              aria-label="hourly_rate_max"
            />
          </div>
          per hour
          {hourlyRateMaxFieldHasError ? <span className="is-invalid" /> : null}
          {hourlyRateMaxFieldHasError ? (
            <div className="invalid-feedback d-block">
              {hourlyRateMaxFieldError.join(', ')}
            </div>
          ) : null}
        </fieldset>
      </div>
    );
  }

  return (
    <div className="my-3 my-sm-4 panel rounded border-1 py-4 px-3 p-sm-4">
      <div className="btn-group mb-3 mb-sm-4">
        <label
          htmlFor={`${formId}_remunerationTypeSalary`}
          className={`btn ${
            currentRemunerationType === 'salary'
              ? 'btn-primary'
              : 'btn-outline-gray-500'
          }`}
        >
          Salary
        </label>
        <label
          htmlFor={`${formId}_remunerationTypeHourlyRate`}
          className={`btn ${
            currentRemunerationType !== 'salary'
              ? 'btn-primary'
              : 'btn-outline-gray-500'
          }`}
        >
          Hourly Rate
        </label>
      </div>
      <input
        type="radio"
        id={`${formId}_remunerationTypeSalary`}
        value="salary"
        name={`${formId}[remuneration_type]`}
        checked={currentRemunerationType === 'salary'}
        onChange={event => onRemunerationTypeChange(event.target.value)}
        className="visually-hidden"
      />
      <input
        type="radio"
        id={`${formId}_remunerationTypeHourlyRate`}
        value="hourly_rate"
        name={`${formId}[remuneration_type]`}
        checked={currentRemunerationType !== 'salary'}
        onChange={event => onRemunerationTypeChange(event.target.value)}
        className="visually-hidden"
      />
      {currentRemunerationType === 'salary'
        ? getSalaryFields()
        : getHourlyRateFields()}
    </div>
  );
};

export default Remuneration;
