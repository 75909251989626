import { Asset } from 'contentful';
import { useEffect, useState } from 'preact/compat';
import React from 'react';
import contentfulClient from '../../js/contentful/client';

interface AssetLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  readonly innerText?: string;
  readonly children?: React.ReactNode;
  readonly title: string;
}

const AssetLink: React.FC<AssetLinkProps> = ({
  title,
  children,
  innerText,
  ...props
}) => {
  const [asset, setAsset] = useState<Asset | null>(null);

  useEffect(() => {
    const client = contentfulClient();

    client
      .getAssets({
        'query': {
          limit: 1,
          order: 'sys.createdAt'
        },
        'fields.title': title
      })
      .then(collection => setAsset(collection.items[0]))
      .catch(console.error);
  }, [title]);

  return (
    asset && (
      <a href={asset.fields.file.url} {...props}>
        {children ?? innerText}
      </a>
    )
  );
};

export default AssetLink;
