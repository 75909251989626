import chunk from 'lodash-es/chunk';
import React from 'react';
import { useState } from 'react';
import Carousel from './Carousel';
import CustomSelect from './CustomSelect';
import useWindowSize from '../hooks/useWindowSize';

const desktopWidth = 1320;
const tabletWidth = 960;

const determineCarouselLayoutDimensions = (
  partner: boolean,
  width: number
): [imageWidth: number, itemCount: number] => {
  let imageWidth = 334;
  let itemCount = 1;

  // non partner carousel widths
  if (width > tabletWidth) {
    imageWidth = 232;
    itemCount = 3;
  }

  if (width > desktopWidth) {
    imageWidth = 212;
    itemCount = 5;
  }

  // partner carousel widths
  if (partner) {
    imageWidth = 155;
    itemCount = 2;

    if (width > tabletWidth) {
      imageWidth = 168;
      itemCount = 4;
    }

    if (width > desktopWidth) {
      imageWidth = 240;
      itemCount = 5;
    }
  }

  return [imageWidth, itemCount];
};

export const CarouselImage: React.FC<{
  readonly items: CarouselImage[];
  readonly imageOnlyCarouselId: string;
  readonly isPartner: boolean;
  readonly locations: CustomSelectOptions;
  readonly heading: string;
}> = ({ items, imageOnlyCarouselId, isPartner, locations, heading }) => {
  const windowSize = useWindowSize();

  const [imageWidth, itemCount] = determineCarouselLayoutDimensions(
    isPartner,
    windowSize.width ?? 0
  );

  const [selectedLocation, setSelectedLocation] = useState(' ');
  const [numTimesSelectedLocationChanged, setNumTimesSelectedLocationChanged] =
    useState(0);

  const onLocationChange = (selectedValue: string) => {
    setNumTimesSelectedLocationChanged(numTimesSelectedLocationChanged + 1);
    setSelectedLocation(selectedValue);
  };

  const carouselSlideItems = chunk(
    selectedLocation === ' '
      ? items
      : items
          .filter(
            carouselItem => carouselItem.locationId === Number(selectedLocation)
          )
          .sort((a, b) => Number(a.regionOrder) - Number(b.regionOrder)),
    itemCount
  );

  return (
    <div className="d-flex flex-column align-items-start pb-4 bg-white">
      <div className="w-100 d-flex ">
        <h2 className="h2 pe-1 pt-1">{heading}</h2>

        {!isPartner && (
          <CustomSelect
            id="location"
            name="location"
            options={locations}
            placeholder="All Regions"
            includePlaceholderOption
            className="pe-4 border-0"
            onChange={selected => onLocationChange(selected.value)}
          />
        )}
      </div>

      {/* The -version-${numTimesSelectedLocationChanged}-${imageWidth} in the carouselId and key attributes */}
      {/* ensure that both the indicators created inside the Carousel component and the carouselSlideItems inside this compenent */}
      {/* rerender whenever either changes - otherwise, if you are on page 3 of the indicators and either the */}
      {/* windows gets smalled or the dropdown changes and there are no longer 3 pages of cards */}
      {/* then you are presented with a page of cards that doesn't exist - you see nothing and the indicators are broken */}
      {/* the Carousel compenent has a comment to that effect as its intended useage */}
      <div className="w-100 align-items-center">
        <Carousel
          carouselItems={carouselSlideItems}
          carouselId={`imageonly-images-${imageOnlyCarouselId}-version-${numTimesSelectedLocationChanged}-${imageWidth}`}
        >
          {carouselSlideItems.map((carouselItems, idx) => (
            <div
              key={`${carouselItems[0].id}-slide-group-of-${itemCount}-version-${numTimesSelectedLocationChanged}-${imageWidth}`}
              className={`carousel-item ${idx === 0 ? 'active' : ''}`}
            >
              <div className="d-flex justify-content-center align-items-center w-100">
                {carouselItems.map(({ id, description, clickUrl, image }) => (
                  <div
                    key={`${id}-slide`}
                    data-testid={`${id}-slide`}
                    className="mt-3 mb-5 h-100 carousel-image"
                  >
                    <a
                      href={clickUrl}
                      data-testid={`${id}-link`}
                      className="text-decoration-none"
                    >
                      <img
                        className={`me-auto ${
                          isPartner ? '' : 'rounded carousel-image-border'
                        }`}
                        src={image}
                        width={imageWidth}
                        alt={description}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselImage;
