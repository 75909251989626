/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  type Options as RenderOptions,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  Block,
  Document,
  INLINES,
  Inline
} from '@contentful/rich-text-types';
import embed from 'embed-video';
import React from 'preact/compat';
import { ReactNode } from 'react'; // We're still using Preact, we just need this type

export type { RenderOptions };

const isEmbeddableUrl = (url: string) => {
  // currently the embed library will error if there isn't a hostname,
  // so we have to actually explicitly handle if an error is thrown
  try {
    return !!embed.info(url);
  } catch {
    return false;
  }
};

export const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node: Block | Inline, children: ReactNode) => (
      <h1 className="h1 text-dark">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (_node: Block | Inline, children: ReactNode) => (
      <h1 className="h2 text-dark">{children}</h1>
    ),
    [BLOCKS.HEADING_3]: (_node: Block | Inline, children: ReactNode) => (
      <h1 className="h3 text-dark">{children}</h1>
    ),
    [BLOCKS.HEADING_4]: (_node: Block | Inline, children: ReactNode) => (
      <h1 className="h4 text-dark">{children}</h1>
    ),
    [BLOCKS.HEADING_5]: (_node: Block | Inline, children: ReactNode) => (
      <h1 className="h5 text-dark">{children}</h1>
    ),
    [BLOCKS.HEADING_6]: (_node: Block | Inline, children: ReactNode) => (
      <h1 className="h6 text-dark">{children}</h1>
    ),
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      if (isEmbeddableUrl(node.data.uri as string)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const html = embed(node.data.uri as string)!;

        return (
          <div
            className="contentful-video-embed ratio ratio-16x9 my-5 w-100"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: html }}
          />
        );
      }

      return <a href={node.data.uri as string | undefined}>{children}</a>;
    },
    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children: ReactNode) => (
      <p>{children}</p>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline, _children: ReactNode) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return node.data.target?.fields?.file?.details?.image ? (
        <img
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          src={`https://${node.data.target.fields.file.url as string}`}
          className="w-100"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          alt={node.data.target.fields.description as string}
        />
      ) : null;
    }
  }
};

const RichTextContent: React.FC<{
  content: Document;
  renderOptions?: RenderOptions;
}> = ({ content, renderOptions = {} }) => {
  return (
    <>
      {documentToReactComponents(content, {
        ...options,
        ...renderOptions
      })}
    </>
  );
};

export default RichTextContent;
