import SampleIcon from '../../images/sample.svg';
import AssetLink from '../contentful/AssetLink';

const SampleJobLink: React.FC = () => (
  <AssetLink
    className="panel p-3 rounded d-flex justify-content-between align-items-center text-decoration-none"
    title="Sample Job"
    target="_blank"
  >
    <span className="fw-bold text-secondary fs-5">View Sample Job</span>
    <img src={SampleIcon} width="24" height="24" alt="Sample job icon" />
  </AssetLink>
);

export default SampleJobLink;
