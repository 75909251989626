import { Entry } from 'contentful';
import React from 'react';
import useContentful from '../../js/contentful/useContentful';
import {
  ICallToActionFields,
  ICallToActionListFields
} from '../../types/contentful';

export const FeatureCard: React.FC<{
  readonly item: Entry<ICallToActionFields>;
}> = ({ item }) => {
  const { title, description, image } = item.fields;

  return (
    <div className="px-lg-3 px-5 mx-auto mx-lg-2 col-10 col-md-6 col-lg-3 h-100 flex-column d-flex">
      {image && (
        <img
          src={`${image.fields.file.url}?w=100`}
          alt={image.fields.title}
          className="col-3 col-md-4 col-lg-5 col-xl-4 mb-3 min-width-no-hard-wrap"
        />
      )}
      <h2 className="fs-2 fw-bold text-secondary">{title}</h2>
      {description && <div className="my-3">{description}</div>}
    </div>
  );
};

const ListingFeatures: React.FC = () => {
  const { fetched, data } = useContentful<ICallToActionListFields>({
    contentType: 'callToActionList',
    query: { 'fields.location': 'List A Job Page' }
  });

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    return null;
  }

  const { callsToAction } = data.items[0].fields;

  return (
    <div className="container">
      <div className="d-flex flex-wrap justify-content-around">
        {callsToAction.map(callToAction => {
          return (
            <FeatureCard key={callToAction.fields.title} item={callToAction} />
          );
        })}
      </div>
    </div>
  );
};

export default ListingFeatures;
