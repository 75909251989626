import { Entry, EntryCollection } from 'contentful';
import resolveResponse from 'contentful-resolve-response';
import React from 'react';
import { ICategoryContentFields } from '../../types/contentful';

const ServerRenderedSEO: React.FC<{
  // This type is extremely forgiving, since this data comes from the backend.
  // We have extra defensiveness built into this component to guard against
  // missing data, which is why we allow this to be a partial of the entry collection,
  // or null
  readonly entryCollection: Partial<
    EntryCollection<ICategoryContentFields>
  > | null;
}> = ({ entryCollection }) => {
  if (!entryCollection?.items) {
    return null;
  }

  const categoryCollection = resolveResponse(entryCollection, {
    removeUnresolved: true
  }) as Array<Entry<ICategoryContentFields>>;
  const category = categoryCollection.at(0);

  if (!category) {
    return null;
  }

  const { categoryName, heroImage, keywords, excerpt } = category.fields;
  const title = `Megajobs - ${categoryName}`;

  const createdAt = new Date(category.sys.createdAt);
  const updatedAt = new Date(category.sys.updatedAt);
  const heroImageUrl = (heroImage ? heroImage.fields.file.url : '').replace(
    /^\/\//u,
    'https://'
  );

  const metas: Record<string, string | undefined> = {
    'og:title': title,
    'twitter:title': title,
    'twitter:description': excerpt,
    'description': excerpt,
    'og:description': excerpt,
    'og:image': heroImageUrl,
    'keywords': keywords?.toString(),
    'twitter:image:src': heroImageUrl,
    'DCTERMS.created': createdAt.toISOString(),
    'DCTERMS.modified': updatedAt.toISOString()
  };

  return (
    <>
      <title>{title}</title>
      {Object.keys(metas).map(metaKey => (
        <meta key={metaKey} name={metaKey} content={metas[metaKey]} />
      ))}
    </>
  );
};

export default ServerRenderedSEO;
