/* eslint-disable @typescript-eslint/unbound-method */
import { Controller } from '@hotwired/stimulus';

export default class FilePickerController extends Controller<HTMLElement> {
  private declare readonly inputTarget: HTMLInputElement;
  private declare readonly filenameTarget: HTMLElement;
  private declare readonly imagePreviewTarget: HTMLImageElement;
  private declare readonly presenceBasedTargets: HTMLElement[];
  private declare readonly absenceBasedTargets: HTMLElement[];
  private declare readonly hasImagePreviewTarget: boolean;
  private existingFilename: string | null = null;

  public static targets = [
    'input',
    'filename',
    'presenceBased',
    'absenceBased',
    'imagePreview'
  ];

  public static values = {
    filename: String
  };

  public initialize(): void {
    this._onChange = this._onChange.bind(this);
  }

  public connect(): void {
    this.inputTarget.addEventListener('change', this._onChange);
    this.existingFilename = this.filenameTarget.textContent;
    this._onChange();
  }

  public disconnect(): void {
    this.inputTarget.removeEventListener('change', this._onChange);
  }

  public reset(): void {
    // Often, a file input will be paired with a hidden field so that an
    // already-attached file is retained when editing a record. This block
    // properly resets this, reflecting that if the user wants to reset the input,
    // we should clear both the file field, and any other fields that are used to
    // store the attachment state, which will cause the attachment to be detached
    // rather than just clearing the 'new' state and keeping the old.
    document
      .querySelectorAll<HTMLInputElement>(`[name="${this.inputTarget.name}"]`)
      .forEach(field => (field.value = ''));
    this.existingFilename = null;
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }));
  }

  private _onChange(): void {
    const firstFile = this.inputTarget.files?.item(0);
    const hasValue = !!firstFile || (this.existingFilename?.length ?? 0) > 0;

    this.filenameTarget.textContent = firstFile?.name ?? this.existingFilename;

    this.presenceBasedTargets.forEach(el =>
      el.classList.toggle('d-none', !hasValue)
    );

    this.absenceBasedTargets.forEach(el =>
      el.classList.toggle('d-none', hasValue)
    );

    if (this.hasImagePreviewTarget) {
      if (!hasValue) {
        this.imagePreviewTarget.classList.add('d-none');

        return;
      }

      if (firstFile?.type.startsWith('image/')) {
        const reader = new FileReader();

        reader.readAsDataURL(firstFile);

        reader.onloadend = () => {
          this.imagePreviewTarget.classList.remove('d-none');
          if (typeof reader.result === 'string') {
            this.imagePreviewTarget.src = reader.result;
          }
        };
      }
    }
  }
}
