import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactSelectInterface from 'react-select/dist/declarations/src/Select';
import CustomSelect from '../CustomSelect';

interface LocationsProps {
  readonly formName: string;
  readonly formId: string;
  readonly currentLocationValue?: string;
  readonly currentSubLocationValue?: string;
  readonly locationOptions: CustomSelectOptions;
  readonly subLocationOptions: SelectOptionsWithParents;
  readonly locationFieldHasError: boolean;
  readonly locationFieldError: string;
  readonly subLocationFieldHasError: boolean;
  readonly subLocationFieldError: string;
  readonly locationFieldPlaceholder: string;
  readonly subLocationFieldPlaceholder: string;
}

const Locations: React.FC<LocationsProps> = ({
  formName,
  formId,
  currentLocationValue,
  currentSubLocationValue,
  locationOptions,
  subLocationOptions,
  locationFieldHasError,
  locationFieldError,
  subLocationFieldHasError,
  subLocationFieldError,
  locationFieldPlaceholder,
  subLocationFieldPlaceholder
}) => {
  const [currentLocation, setCurrentLocation] = useState(currentLocationValue);
  const [currentSubLocationOptions, setSubLocationOptions] =
    useState(subLocationOptions);
  const subLocationInputRef = useRef<ReactSelectInterface>(null);
  const onCurrentLocationChange = (selected: {
    label: string;
    value: number | string;
  }) => {
    filterSubLocations(selected.value);
    setCurrentLocation(selected.label);

    if (subLocationInputRef.current) {
      subLocationInputRef.current.clearValue();
    }
  };

  const filterSubLocations = useCallback(
    (parentId: number | string) => {
      const filteredSubLocations = subLocationOptions.filter(
        location => location.parent_id === Number(parentId)
      );

      setSubLocationOptions(filteredSubLocations);
    },
    [subLocationOptions]
  );

  useEffect(() => {
    if (currentLocation) {
      if (subLocationOptions === currentSubLocationOptions) {
        filterSubLocations(currentLocation);
      }
    }
  }, [
    currentLocation,
    currentSubLocationOptions,
    filterSubLocations,
    subLocationOptions
  ]);

  return (
    <>
      <div className="mb-3">
        <CustomSelect
          label="Primary Location of this Role"
          options={locationOptions}
          id={`${formId}_location`}
          hasError={locationFieldHasError}
          name={`${formName}[location_id]`}
          placeholder={locationFieldPlaceholder}
          onChange={onCurrentLocationChange}
          initialValue={currentLocation}
          required
        />
        {locationFieldHasError ? <span className="is-invalid" /> : null}
        {locationFieldError && (
          <div className="invalid-feedback d-block">{locationFieldError}</div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor={`${formId}_sub_location`} className="visually-hidden">
          Sublocation
        </label>
        <CustomSelect
          options={currentSubLocationOptions}
          id={`${formId}_sub_location`}
          hasError={locationFieldHasError}
          name={`${formName}[sub_location_id]`}
          placeholder={subLocationFieldPlaceholder}
          isDisabled={!currentLocation}
          initialValue={currentSubLocationValue}
          customRef={subLocationInputRef}
          required
          data-testid="sub_location_field"
        />
        {subLocationFieldHasError ? <span className="is-invalid" /> : null}
        {subLocationFieldError && (
          <div className="invalid-feedback d-block">
            {subLocationFieldError}
          </div>
        )}
      </div>
    </>
  );
};

export default Locations;
