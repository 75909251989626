import { Controller } from '@hotwired/stimulus';

export interface TrixEditor {
  value: string;
  element: HTMLElement;
  insertString(str: string): void;
  recordUndoEntry(key: string): void;
}

export interface TrixEditorElement extends HTMLTextAreaElement {
  toolbarElement: HTMLElement;
  editor: TrixEditor;
}

export default class TrixEditorController extends Controller<TrixEditorElement> {
  private toolbar!: HTMLElement;
  private readonly observer: MutationObserver = new MutationObserver(
    mutationRecords => this._mutationObserverCallback(mutationRecords)
  );

  public connect(): void {
    this.toolbar = this.element.toolbarElement;
    this.element.addEventListener('trix-change', () => {
      this.element.dispatchEvent(new Event('change'));
    });

    this.element.checkValidity = () => this.checkValidity();
    this.observer.observe(this.element, {
      attributeFilter: ['disabled'],
      attributeOldValue: true
    });

    // Block drag-and-drop file uploads
    this.element.addEventListener('trix-file-accept', event =>
      event.preventDefault()
    );
  }

  public disconnect(): void {
    this.observer.disconnect();
  }

  public disable(): void {
    this.toolbar.setAttribute('disabled', 'disabled');
    this.toolbar
      .querySelectorAll<HTMLButtonElement>('.trix-button')
      .forEach(btn => (btn.disabled = true));

    this.element.editor.element.contentEditable = 'false';
  }

  public enable(): void {
    this.toolbar.removeAttribute('disabled');
    this.toolbar
      .querySelectorAll<HTMLButtonElement>('.trix-button')
      .forEach(btn => (btn.disabled = false));
    this.element.editor.element.contentEditable = 'true';
  }

  public checkValidity(): boolean {
    if (
      this.element.getAttribute('disabled') ??
      !this.element.getAttribute('required')
    ) {
      return true;
    }

    return this.element.value.length > 0;
  }

  private _mutationObserverCallback(mutationList: MutationRecord[]) {
    mutationList.forEach(record => {
      if (record.type === 'attributes' && record.attributeName === 'disabled') {
        // Old value being null means the element was not there, but now is.
        // This matches the normal disabled attribute behaviour which doesn't care
        // what the attribute value actually is, just that it is there.
        if (!record.oldValue) {
          this.disable(); // Attribute has gone from no value to value -> attribute was added
        } else {
          this.enable(); // Attribute has gone from value to no value -> attribute was removed
        }
      }
    });
  }
}
