import { Entry } from 'contentful';
import React from 'react';
import Markdown from 'react-markdown';
import GfmMarkdown from 'remark-gfm';
import useContentful from '../../js/contentful/useContentful';
import {
  ICallToActionFields,
  ICallToActionListFields
} from '../../types/contentful';

const CallToActionCard: React.FC<{
  readonly callToAction: Entry<ICallToActionFields>;
}> = ({ callToAction }) => {
  const {
    title,
    description,
    callToActionText,
    callToActionUrl,
    secondaryCallToActionText,
    secondaryCallToActionUrl,
    image,
    accentColour
  } = callToAction.fields;

  return (
    <div className={`cta-card ${image ? 'cta-image-card' : ''}`}>
      {image && (
        <img
          src={`${image.fields.file.url}?w=1400`}
          alt={image.fields.title}
          className="rounded-top cta-image w-100"
        />
      )}
      <div
        className="text-white px-5 py-4 mb-2 d-flex flex-column justify-content-between flex-grow-1 cta-description"
        style={{ backgroundColor: accentColour }}
      >
        <h2 className="fs-2 fw-bold">{title}</h2>
        {description && (
          <div className="my-4">
            <Markdown remarkPlugins={[GfmMarkdown]} linkTarget="_blank">
              {description}
            </Markdown>
          </div>
        )}
        <div className="row">
          {callToActionText && callToActionUrl && (
            <div className="col-12 col-sm-auto px-1">
              <a
                className="btn my-2 cta-button cta-button-primary"
                style={{ color: accentColour }}
                href={callToActionUrl}
              >
                {callToActionText}
              </a>
            </div>
          )}
          {secondaryCallToActionText && secondaryCallToActionUrl && (
            <div className="col-12 col-sm-auto px-1">
              <a
                className="btn border-white border-2 text-white my-2 cta-button cta-button-secondary"
                style={{ color: accentColour }}
                href={secondaryCallToActionUrl}
              >
                {secondaryCallToActionText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ResourcesCallToAction: React.FC<{
  readonly page: string;
  readonly audience?: Audience;
}> = ({ page, audience }) => {
  const { fetched, data } = useContentful<ICallToActionListFields>({
    contentType: 'callToActionList',
    query: {
      'fields.location': page,
      'fields.audience': audience
    }
  });

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    return null;
  }

  const { callsToAction } = data.items[0].fields;

  return (
    <div className="row">
      {callsToAction.map(callToAction => (
        <CallToActionCard
          callToAction={callToAction}
          key={callToAction.fields.title}
        />
      ))}
    </div>
  );
};

export default ResourcesCallToAction;
