declare global {
  interface Window {
    dataLayer?: [];
  }
}

declare global {
  interface DocumentEventMap {
    'turbo:load': TurboEvent;
  }
}

const sendGtagEvent = (e: TurboEvent) => {
  const GA_ID = process.env.GOOGLE_ANALYTICS_ID;

  window.dataLayer = window.dataLayer ?? [];

  function gtag(...args: unknown[]) {
    window.dataLayer?.push(args as never);
  }

  gtag('js', new Date());
  gtag('config', GA_ID, { page_location: e.detail.url });
};

const pageLoadAnalyticsListner = (): void => {
  document.addEventListener('turbo:load', sendGtagEvent);
};

export default pageLoadAnalyticsListner;
