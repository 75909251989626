import { Entry } from 'contentful';
import capitalize from 'lodash-es/capitalize';
import { useCallback } from 'preact/hooks';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import PlayIcon from '../../images/play.svg';
import useContentful from '../../js/contentful/useContentful';
import { IResourceFields } from '../../types/contentful';

const ImagePlaceholder: React.FC<{
  readonly url: string;
  readonly title: string;
}> = ({ url, title }) => {
  return (
    <span className="col-12 col-md-5 col-lg-4">
      <img src={`${url}?w=516`} alt={title} className="rounded-3 w-100" />
    </span>
  );
};

const VideoPlaceholder: React.FC<{
  readonly url: string;
  readonly title: string;
}> = ({ url, title }) => {
  return (
    <span className="col-12 col-md-5 col-lg-4">
      <div className="overlay-container ">
        <img src={`${url}?w=516`} alt={title} className="rounded-3 w-100" />
        <div className="overlay" />
        <span className="icon">
          <img src={PlayIcon} alt="play-icon" />
        </span>
      </div>
    </span>
  );
};

export const ResourceList: React.FC<{
  readonly resources: Array<Entry<IResourceFields>>;
  readonly audience?: Audience;
}> = ({ resources, audience }) => {
  return (
    <>
      {resources.map(resource => {
        const { title, cardImage, summary, publishedAt, slug, category } =
          resource.fields;

        const datePublished = new Date(
          publishedAt ?? resource.sys.createdAt
        ).toLocaleDateString('en-NZ', {
          year: 'numeric',
          day: '2-digit',
          month: 'long'
        });

        // Contentful Types lie - this field is required, but a draft asset is sufficient,
        // which means this field CAN in fact be null.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!cardImage) {
          console.warn(
            `Resource ${title} is missing an image so was skipped from rendering`
          );

          return;
        }

        return (
          <a
            className="row flex-md-row-reverse mb-5 pt-2 text-decoration-none"
            key={title}
            href={`/resources/${slug}?landing_page=${
              audience ? audience.toLowerCase().replace(/\s/gu, '') : ''
            }&category=${category.toLowerCase()}`}
            target="_top"
          >
            <div className="col-12 col-md-7 col-lg-8">
              <h2 className="h3 text-secondary mb-2 ">{title}</h2>
              <p className="mb-2 small">{datePublished}</p>
              <p>{summary}</p>
            </div>
            {category === 'Videos' ? (
              <VideoPlaceholder
                url={cardImage.fields.file.url}
                title={cardImage.fields.title}
              />
            ) : (
              <ImagePlaceholder
                url={cardImage.fields.file.url}
                title={cardImage.fields.title}
              />
            )}
          </a>
        );
      })}
    </>
  );
};

const PaginatedResourceList: React.FC<{
  readonly audience: Audience;
  readonly itemsPerPage?: number;
  readonly items: Array<Entry<IResourceFields>>;
}> = ({ itemsPerPage = 5, items, audience }) => {
  const [resources, setResources] = useState<Array<Entry<IResourceFields>>>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setResources(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = useCallback(
    (event: { selected: number }) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;

      setItemOffset(newOffset);
    },
    [items, itemsPerPage]
  );

  return (
    <div>
      <ResourceList resources={resources} audience={audience} />
      <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        breakLabel="..."
        breakClassName="ms-2 me-3 fw-bold text-secondary d-flex align-items-end"
        className="list-inline d-flex justify-content-center"
        pageClassName="list-inline-item rounded-circle border border-secondary py-1"
        pageLinkClassName="text-decoration-none pagination-page-link d-block text-secondary"
        previousLabel="Previous"
        previousClassName={`list-inline-item ${pageCount < 6 ? 'd-none' : ''}`}
        previousLinkClassName="text-secondary fw-bold text-decoration-none px-3 py-2 d-block"
        nextLabel="Next"
        nextClassName={` list-inline-item ${pageCount < 6 ? 'd-none' : ''}`}
        nextLinkClassName="text-secondary fw-bold text-decoration-none px-3 py-2 d-block"
        activeClassName="bg-secondary"
        activeLinkClassName="text-white fw-bold"
        disabledClassName="d-none"
        disabledLinkClassName=""
      />
    </div>
  );
};

interface ResourceListWrapperProps {
  readonly audience: Audience;
  readonly category: 'news' | 'resources' | 'videos';
}

const ResourceListWrapper: React.FC<ResourceListWrapperProps> = ({
  audience,
  category
}) => {
  const { fetched, data } = useContentful<IResourceFields>({
    contentType: 'resource',
    query: {
      'fields.audience[in]': `${audience},Both`,
      'fields.category': capitalize(category)
    }
  });

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    window.location.pathname = '/resources';

    return null;
  }

  const { items } = data;

  // Due to the requirement to sort this list by either
  // publishedAt or createdAt, we are unable to do this via
  // the contentful API which would be the preferred solution
  // so instead we have to do this manually here.

  const orderedItems = items
    .sort(
      (resource1, resource2) =>
        Date.parse(resource1.fields.publishedAt ?? resource1.sys.createdAt) -
        Date.parse(resource2.fields.publishedAt ?? resource2.sys.createdAt)
    )
    .reverse();

  return <PaginatedResourceList items={orderedItems} audience={audience} />;
};

export default ResourceListWrapper;
