import FacebookShareIcon from '../images/facebookShare.svg';
import LinkedinShareIcon from '../images/linkedinShare.svg';
import TwitterShareIcon from '../images/twitterShare.svg';

const ShareLinks: React.FC<{ url: string }> = ({ url }) => (
  <div>
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`}
      target="_blank"
      rel="noreferrer"
      title="Share on Facebook"
    >
      <img src={FacebookShareIcon} alt="Facebook" />
    </a>
    <a
      href={`http://www.twitter.com/intent/tweet?url=${encodeURIComponent(
        url
      )}`}
      target="_blank"
      rel="noreferrer"
      title="Share on Twitter"
    >
      <img src={TwitterShareIcon} alt="Twitter" />
    </a>
    <a
      href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`}
      target="_blank"
      rel="noreferrer"
      title="Share on LinkedIn"
    >
      <img src={LinkedinShareIcon} alt="LinkedIn" />
    </a>
  </div>
);

export default ShareLinks;
