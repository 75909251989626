import { ContentfulClientApi, EntryCollection } from 'contentful';
import { useEffect, useState } from 'preact/compat';
import contentfulClient from './client';

interface UseContentfulProps {
  contentType: string | null;
  include?: number | null;
  locale?: string | null;
  query?: Record<string, unknown>;
}

interface FetchDataProps extends UseContentfulProps {
  client: ContentfulClientApi;
}

export const fetchData = async <T>(
  props: FetchDataProps
): Promise<EntryCollection<T>> => {
  const { client, contentType, include, locale, query } = props;

  return client.getEntries<T>({
    content_type: contentType,
    locale,
    include,
    ...query
  });
};

const useContentful = <T>(
  props: UseContentfulProps
): {
  data: EntryCollection<T> | null;
  loading: boolean;
  error: string | null;
  fetched: boolean;
} => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<EntryCollection<T> | null>(null);
  const [fetched, setFetched] = useState<boolean>(!!data);

  // Props are stringified so we have a stable value to compare
  // against in the useEffect hook
  const stringifiedProps = JSON.stringify(props);

  useEffect(() => {
    if (fetched) {
      setFetched(false);
    }

    const requestContentfulData = () => {
      setLoading(true);

      fetchData<T>({ ...props, client: contentfulClient() })
        .then(response => {
          setData(response);
          setLoading(false);
          setFetched(true);
        })
        .catch((err: string) => {
          setError(err);
        });
    };

    requestContentfulData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedProps]);

  const finish = () => ({ data, error, fetched, loading });

  return finish();
};

export default useContentful;
