import { useMemo, useState } from 'react';
import ReactSelect, { ActionMeta, MultiValue, SingleValue } from 'react-select';
import MultiValueComponent from './NestedMultiValueContainer';
import OptionComponent from './NestedOption';
import { groupOptions, normalizeOptions } from './helpers';
import styles from './styles';

interface NestedMultiSelectProps {
  className?: string;
  id: string;
  initialValue?: string[];
  label?: string;
  menuIsOpen?: boolean; // for testing
  name: string;
  optionGroups?: boolean;
  options: Array<{
    label: string;
    value: string;
    parent_id?: string;
    principality?: string;
  }>;
  placeholder: string;
}

const NestedMultiSelect: React.FC<NestedMultiSelectProps> = ({
  className,
  id,
  initialValue,
  optionGroups,
  label: fieldLabel,
  name: fieldName,
  options,
  ...rest
}) => {
  const normalizedOptions = useMemo(() => normalizeOptions(options), [options]);

  const groupedOptions = optionGroups
    ? groupOptions(normalizedOptions)
    : undefined;

  const defaultOptions = initialValue
    ?.map(sel =>
      normalizedOptions.find((opt: { value: string }) => opt.value === sel)
    )
    .filter(
      (sel): sel is NestedMultiSelectOptionInterface => sel !== undefined
    );

  const [selectedValues, setSelectedValues] = useState(defaultOptions ?? []);

  const childChange = (option: NestedMultiSelectOptionInterface): void => {
    const checked = !!selectedValues.find(sel => {
      return sel.value === option.value;
    });

    switch (checked) {
      case false:
        setSelectedValues([
          ...selectedValues.filter(sel => {
            // Remove the parent of the child from the array of selectedValues
            return sel.value !== option.parentId;
          }),
          option
        ]);
        break;

      default:
        setSelectedValues(
          selectedValues.filter(sel => sel.value !== option.value)
        );

        break;
    }
  };

  const parentChange = (
    meta: ActionMeta<NestedMultiSelectOptionInterface>
  ): void => {
    switch (meta.action) {
      case 'select-option':
        setSelectedValues(
          [
            ...selectedValues.filter(sel => {
              // Remove any children of the parent from the array of selectedValues
              return sel.parentId !== meta.option?.value;
            }),
            meta.option
          ].filter(
            (sel): sel is NestedMultiSelectOptionInterface => sel !== undefined
          )
        );

        break;

      case 'deselect-option':
      case 'remove-value':
      case 'pop-value':
        setSelectedValues(
          selectedValues.filter(sel => {
            // Remove the parent and any children of that parent from the array of selectedValues
            return (
              sel.value !== meta.option?.value &&
              sel.parentId !== meta.option?.value
            );
          })
        );

        break;
      // Meta actions which trigger the default
      // | 'clear'
      // | 'create-option';
      default:
        console.error(`Meta action ${meta.action} has not been implemented`);
    }
  };

  const onChange = (
    _newValue:
      | MultiValue<NestedMultiSelectOptionInterface>
      | SingleValue<NestedMultiSelectOptionInterface>,
    meta: ActionMeta<NestedMultiSelectOptionInterface>
  ): void => {
    if (meta.option?.parentId) {
      childChange(meta.option);
    } else {
      parentChange(meta);
    }
  };

  /** React-Select does not emit native change events on the hidden input
   *  field that it creates. This onChange wrapper fixes that by emitting a change
   *  event on the input when the select value changes. If a React-Select onChange
   *  prop is given, it'll then call that with the original args.
   */
  const wrappedOnChange = (
    newValue:
      | MultiValue<NestedMultiSelectOptionInterface>
      | SingleValue<NestedMultiSelectOptionInterface>,
    meta: ActionMeta<NestedMultiSelectOptionInterface>
  ) => {
    if (meta.action === 'select-option') {
      const input = document.querySelector<HTMLInputElement>(
        `input[name='${meta.name ?? 'custom-select'}']`
      );
      const event = new Event('change', { bubbles: true, cancelable: true });

      input?.dispatchEvent(event);
    }

    onChange(newValue, meta);
  };

  return (
    <div data-testid="multi-select-test-id">
      <ReactSelect
        {...rest}
        aria-label={`Select ${fieldLabel ?? 'option'}`}
        className={`form-select ${className ? className : ''}`}
        closeMenuOnSelect={false}
        components={{
          // Type '(props: CustomMultiValueProps) => ComponentType<MultiValueProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>> | undefined'
          // is not assignable to type
          // 'ComponentType<MultiValueProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>> | undefined'
          // @ts-expect-error TS have tried to unpick the type web unsuccessfully
          MultiValue: MultiValueComponent,
          // Type '(props: CustomOptionProps) => ComponentType<MultiValueProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>> | undefined'
          // is not assignable to type
          // 'ComponentType<OptionProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>> | undefined'
          // @ts-expect-error TS have tried to unpick the type web unsuccessfully
          Option: OptionComponent
        }}
        defaultValue={defaultOptions}
        id={id}
        hideSelectedOptions={false}
        initialValue={defaultOptions}
        isMulti
        isClearable={false}
        fieldLabel={fieldLabel}
        // menuIsOpen
        normalizedOptions={normalizedOptions}
        name={fieldName}
        onChange={wrappedOnChange}
        options={optionGroups ? groupedOptions : normalizedOptions}
        styles={styles(id)}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        value={selectedValues}
      />
    </div>
  );
};

export default NestedMultiSelect;
