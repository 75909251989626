import { Entry } from 'contentful';
import React from 'react';
import useContentful from '../js/contentful/useContentful';
import {
  ICallToActionFields,
  ICallToActionListFields
} from '../types/contentful';

export const WelcomeCallToActionCard: React.FC<{
  readonly item: Entry<ICallToActionFields>;
}> = ({ item }) => {
  const { title, description, image, callToActionText, callToActionUrl } =
    item.fields;

  const imageSrc = image?.fields.file.url;

  return (
    <div className="d-flex flex-row mb-5 ">
      {imageSrc && (
        <span className="mt-4">
          <img src={imageSrc} width="90" alt={title} />
        </span>
      )}
      <div className="d-flex flex-column ps-4">
        <h3 className="h3">{title}</h3>
        <p>{description}</p>
        {callToActionText && callToActionUrl && (
          <a
            className="btn btn-secondary-darker btn-sm medium-width mt-3"
            href={callToActionUrl}
          >
            {callToActionText}
          </a>
        )}
      </div>
    </div>
  );
};

const WelcomeCallsToAction: React.FC<{
  readonly audience: Audience;
}> = ({ audience }) => {
  const { fetched, data } = useContentful<ICallToActionListFields>({
    contentType: 'callToActionList',
    query: {
      'fields.location': 'Welcome page',
      'fields.audience': audience
    }
  });

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    return null;
  }

  const { callsToAction } = data.items[0].fields;

  return (
    <>
      {callsToAction.map(callToAction => {
        return (
          <WelcomeCallToActionCard
            key={callToAction.fields.title}
            item={callToAction}
          />
        );
      })}
    </>
  );
};

export default WelcomeCallsToAction;
