import { Controller } from '@hotwired/stimulus';

/**
 * This controller conditionally disables a target based on the number of checkable
 * inputs that are checked up to maxlength.
 * We expect that the element is checkable, since this is the state we check.
 */

export default class ConditionalFieldsController extends Controller<HTMLElement> {
  public static targets = ['disableField', 'enableField', 'trigger'];
  public static values = {
    maxlength: { type: Number, default: 1 }
  };

  private declare readonly triggerTargets: HTMLInputElement[];
  private declare readonly disableFieldTargets: HTMLInputElement[];
  private declare readonly enableFieldTargets: HTMLInputElement[];
  private declare readonly maxlengthValue: number;
  private declare triggerGroup: Set<HTMLInputElement>;

  public connect(): void {
    // We handle change events on all elements with the same name,
    // even though they might not be direct targets.
    // This allows us to know when something was 'unchecked', and
    // update the state of this controller
    this.triggerGroup = new Set<HTMLInputElement>([
      ...this.triggerTargets,
      ...(Array.from(
        document.getElementsByName(this.triggerTargets[0].name)
      ) as HTMLInputElement[])
    ]);

    this.triggerGroup.forEach(trigger =>
      trigger.addEventListener('change', () => this._onChange())
    );

    this.triggerTargets[0].dispatchEvent(new Event('change'));
  }

  public disconnect(): void {
    this.triggerGroup.forEach(trigger =>
      trigger.removeEventListener('change', () => this._onChange())
    );
  }

  private _onChange(): void {
    const checkedTargets = this.triggerTargets.filter(({ checked }) => checked);

    if (checkedTargets.length >= this.maxlengthValue) {
      this.disableFieldTargets
        .filter(field => !checkedTargets.includes(field)) // Don't disable the field if it's also a target
        .forEach(field => field.setAttribute('disabled', 'disabled'));
      this.enableFieldTargets.forEach(field =>
        field.removeAttribute('disabled')
      );
    } else {
      this.disableFieldTargets.forEach(field =>
        field.removeAttribute('disabled')
      );
      this.enableFieldTargets.forEach(field =>
        field.setAttribute('disabled', 'disabled')
      );
    }
  }
}
