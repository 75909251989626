import React from 'react';
import useContentful from '../../js/contentful/useContentful';
import { IPageIntroductionFields } from '../../types/contentful';

const ResourcesBanner: React.FC<{ readonly audience: Audience }> = ({
  audience
}) => {
  const { fetched, data } = useContentful<IPageIntroductionFields>({
    contentType: 'pageIntroduction',
    query: {
      'order': 'sys.createdAt',
      'limit': 1,
      'fields.page': 'Resources',
      'fields.audience': audience
    }
  });

  // Loading state and not found state
  if (!fetched || !data?.items.length) {
    return <div className="banner-image w-100" />;
  }

  const heroImage = data.items[0].fields.heroImage;

  return heroImage ? (
    <div className="banner-image banner-image--constrained">
      <img src={`${heroImage.fields.file.url}`} alt={heroImage.fields.title} />
    </div>
  ) : null;
};

export default ResourcesBanner;
