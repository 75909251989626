import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash-es/debounce';

export default class Autosave extends Controller<
  HTMLFieldSetElement | HTMLFormElement
> {
  private declare readonly delayValue: number;
  private declare readonly performInitialSaveValue: boolean;
  private declare readonly bindFormEventsValue: boolean;

  public static values = {
    delay: Number,
    performInitialSave: Boolean,
    bindFormEvents: Boolean
  };

  public initialize(): void {
    this.save = this.save.bind(this);
  }

  public connect(): void {
    if (this.delayValue > 0) {
      // This function is bound in initialize()
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.save = debounce(this.save, this.delayValue);
    }

    if (this.bindFormEventsValue) {
      this.form.addEventListener('change', () => {
        this.save();
      });
    }

    if (this.performInitialSaveValue) {
      this.save();
    }
  }

  public disconnect(): void {
    if (this.bindFormEventsValue) {
      this.form.removeEventListener('change', () => this.save());
    }
  }

  public get form(): HTMLFormElement {
    return (this.element.form || super.element) as HTMLFormElement;
  }

  public save(): void {
    this.form.requestSubmit();
  }
}
