import sortBy from 'lodash-es/sortBy';

export const nestOptions = (
  options: NestedMultiSelectOptionInterface[]
): NestedMultiSelectOptionInterface[] => {
  const parentOptions = sortBy(
    options.filter(opt => opt.parentId === null),
    'label'
  );

  return parentOptions
    .map(parentOpt => {
      const children = options
        .filter(opt => opt.parentId === parentOpt.value)
        .map(opt => {
          opt.group = parentOpt.group;

          return opt;
        });

      return [parentOpt, ...children];
    })
    .flat();
};

export const normalizeOptions = (
  options: Array<{
    label: string;
    value: string;
    parent_id?: string;
    principality?: string;
  }>
): NestedMultiSelectOptionInterface[] => {
  return nestOptions(
    options.map(opt => {
      return {
        label: opt.label,
        value: opt.value.toString(),
        parentId: opt.parent_id ? opt.parent_id.toString() : null,
        group: opt.principality ?? ''
      };
    })
  );
};

export const groupOptions = (
  options: NestedMultiSelectOptionInterface[]
): GroupedNestedMultiSelectOptionInterface[] => {
  const grouped = options.reduce(
    (groups: Record<string, NestedMultiSelectOptionInterface[]>, opt) => ({
      ...groups,
      // Type is not express properly above, causing a false postive (condition is required)
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      [opt.group]: [...(groups[opt.group] || []), opt]
    }),
    {}
  );

  return Object.keys(grouped).map(label => ({
    label,
    options: grouped[label]
  }));
};
