import { Entry } from 'contentful';
import chunk from 'lodash-es/chunk';
import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import useContentful from '../../js/contentful/useContentful';
import { IResourceFields } from '../../types/contentful';
import Carousel from '../Carousel';

export const FeaturedCard: React.FC<{
  readonly item: Entry<IResourceFields>;
}> = ({ item }) => {
  const { title, cardImage, summary, publishedAt, slug, category, audience } =
    item.fields;

  // Contentful Types lie - this field is required, but a draft asset is sufficient,
  // which means this field CAN in fact be null.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!cardImage) {
    console.warn(
      `Resource ${title} is missing an image so was skipped from rendering`
    );

    return null;
  }

  const datePublished = new Date(
    publishedAt ?? item.sys.createdAt
  ).toLocaleDateString('en-NZ', {
    year: 'numeric',
    day: '2-digit',
    month: 'long'
  });

  const badgeColorMap = {
    'Job Seekers': 'primary',
    'Employers': 'secondary',
    'Resources': 'orange',
    'News': 'yellow',
    'Both': 'muted-secondary'
  };

  return (
    <a
      className={`d-flex flex-column text-decoration-none mt-4 mb-5 rounded-3 resource-card ${
        category === 'Videos' ? 'video-resource-card' : ''
      }`}
      key={title}
      href={`/resources/${slug}`}
    >
      <span className="col-12">
        <img
          src={`${cardImage.fields.file.url}?w=900`}
          alt={cardImage.fields.title}
          className="w-100 rounded-top"
        />
      </span>
      <div className="col-12 p-4">
        <div className="d-flex flex-wrap mt-3 mb-4">
          {/* @ts-expect-error audience is required by the API request but typescript hasn't registered this so yells about possible undefineds*/}
          <span className={`badge badge-${badgeColorMap[audience]}`}>
            For {audience}
          </span>
          {/* @ts-expect-error category is required to be News or Resources by the API request but typescript hasn't registered this so yells about missing key*/}
          <span className={`badge badge-${badgeColorMap[category]} ms-2`}>
            {category}
          </span>
        </div>
        <h2 className="h3 text-secondary mb-2 ">{title}</h2>
        <p className="my-3 small text-black">{datePublished}</p>
        {category !== 'Videos' && (
          <p>
            {summary}
            ...&nbsp;
            <a className="fw-bold text-secondary" href={`/resources/${slug}`}>
              Read More
            </a>
          </p>
        )}
      </div>
    </a>
  );
};

const FeaturedResources: React.FC<{
  readonly id?: string;
  readonly category?: string;
}> = ({ category = 'Resources,News', id = 'ResourcesAndNews' }) => {
  const { fetched, data } = useContentful<IResourceFields>({
    contentType: 'resource',
    query: {
      'order': '-fields.publishedAt',
      'fields.audience[in]': 'Job Seekers,Employers,Both',
      'fields.featured': true,
      'fields.category[in]': category
    }
  });

  const windowSize = useWindowSize();
  let itemCount = 1;

  if (windowSize.width) {
    if (windowSize.width > 960) {
      itemCount = 2;
    }

    if (windowSize.width > 1320) {
      itemCount = 3;
    }
  }

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    return null;
  }

  const { items } = data;
  const carouselItems = chunk(items, itemCount);

  return (
    <Carousel
      carouselItems={carouselItems}
      carouselId={`featured${id}-items-${itemCount}`}
    >
      {carouselItems.map((carouselSlideItems, idx) => {
        return (
          <div
            className={`carousel-item ${idx === 0 ? 'active' : ''}`}
            key={`carousel-slide-${carouselSlideItems[0].fields.title}-of-${itemCount}`}
          >
            <div className="d-block w-75 m-auto">
              <div className="d-flex justify-content-around w-100">
                {carouselSlideItems.map(item => (
                  <FeaturedCard
                    item={item}
                    key={`carousel-item-${item.fields.title}`}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default FeaturedResources;
