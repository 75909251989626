import { Entry } from 'contentful';
import chunk from 'lodash-es/chunk';
import React from 'react';
import Carousel from './Carousel';
import useWindowSize from '../hooks/useWindowSize';
import useContentful from '../js/contentful/useContentful';
import {
  ICallToActionFields,
  ICallToActionListFields
} from '../types/contentful';

export const SupportingCallToActionCard: React.FC<{
  readonly item: Entry<ICallToActionFields>;
}> = ({ item }) => {
  const { title, description, image, accentColour } = item.fields;

  return (
    <div className="supporting-call-to-action-card mb-5 mt-4">
      <div
        className="border-white border text-white mx-3 my-1 p-3 rounded h-100 flex-column align-items-center d-flex"
        style={{ backgroundColor: accentColour }}
      >
        {image && (
          <img
            src={`${image.fields.file.url}?w=100`}
            alt={image.fields.title}
            className="rounded-circle col-4 mb-3"
          />
        )}
        <h2 className="fs-2 fw-bold">{title}</h2>
        {description && <div className="my-3">{description}</div>}
      </div>
    </div>
  );
};

const SupportingCallsToAction: React.FC<{
  readonly page: string;
  readonly audience: Audience;
}> = ({ page, audience }) => {
  const { fetched, data } = useContentful<ICallToActionListFields>({
    contentType: 'callToActionList',
    query: {
      'fields.location': page,
      'fields.audience': audience
    }
  });

  const windowSize = useWindowSize();
  let itemCount = 1;

  if (windowSize.width) {
    if (windowSize.width > 720) {
      itemCount = 2;
    }

    if (windowSize.width > 1200) {
      itemCount = 4;
    }
  }

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    return null;
  }

  const { callsToAction } = data.items[0].fields;
  const carouselItems = chunk(callsToAction, itemCount);

  return itemCount < 4 ? (
    <Carousel
      carouselItems={carouselItems}
      carouselId="supportingCallsToAction"
      theme="light"
    >
      {carouselItems.map((carouselSlideItems, idx) => {
        return (
          <div
            className={`carousel-item ${idx === 0 ? 'active' : ''}`}
            key={`carousel-slide-${carouselSlideItems[0].fields.title}`}
          >
            <div className="d-block w-75 m-auto">
              <div className="d-flex justify-content-around w-100">
                {carouselSlideItems.map(item => (
                  <SupportingCallToActionCard
                    item={item}
                    key={`carousel-item-${item.fields.title}`}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </Carousel>
  ) : (
    <div className="container">
      <div className="d-flex flex-wrap justify-content-between">
        {callsToAction.map(callToAction => {
          return (
            <SupportingCallToActionCard
              key={callToAction.fields.title}
              item={callToAction}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SupportingCallsToAction;
