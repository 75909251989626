import { useEffect } from 'preact/hooks';
import React, { useState } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import next from '../images/next.svg';
import previous from '../images/previous.svg';

interface Link {
  text: string;
  href: string;
  className: string;
  iconUrl?: string;
}

interface HorizontalNavProps {
  readonly links: [Link];
  readonly defaultLink: string;
}

const MobileLinkList: React.FC<HorizontalNavProps> = props => {
  let navItemsNode: HTMLElement | null = null;

  const { links, defaultLink } = props;

  const [currentIndex, setCurrentIndex] = useState(
    links.findIndex(link => link.href === defaultLink)
  );

  const [scrollableValue, setScrollableValue] = useState<boolean>();

  const checkScrollable = () => {
    if (navItemsNode) {
      setScrollableValue(navItemsNode.scrollWidth > navItemsNode.clientWidth);
    }
  };

  // Check scrollability when component renders
  useEffect(checkScrollable);

  // Scroll to the correct nav item
  useEffect(() => {
    if (scrollableValue !== undefined) {
      navItemsNode
        ?.querySelectorAll('.nav-item')
        [currentIndex]?.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }, [navItemsNode, currentIndex, scrollableValue]);

  const canPrevious = currentIndex > 0;
  const canNext = currentIndex < links.length - 1;

  const decrementCurrentIndex = () => {
    if (canPrevious) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const incrementCurrentIndex = () => {
    if (canNext) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <ul className="nav row flex-nowrap text-nowrap fs-5">
      <div className="col-auto pe-0">
        {scrollableValue && canPrevious && (
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={decrementCurrentIndex}
          >
            <img src={previous} className="" alt="previous" />
          </button>
        )}
      </div>
      <div
        className="flex-nowrap overflow-hidden flex-fill d-flex gap-3 p-0"
        ref={node => (navItemsNode = node)}
      >
        {links.map(({ text, href, className }) => {
          return (
            <li className="col col-auto nav-item" key={text}>
              <a href={href} className={className}>
                {text}
              </a>
            </li>
          );
        })}
      </div>
      <div className="col-auto ps-0">
        {scrollableValue && canNext && (
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={incrementCurrentIndex}
          >
            <img src={next} className="" alt="next" />
          </button>
        )}
      </div>
    </ul>
  );
};

const DesktopLinkList: React.FC<{ readonly links: [Link] }> = ({ links }) => {
  return (
    <ul className="nav overflow-auto text-nowrap justify-content-between align-items-center justify-content-xl-start">
      {links.map((link, idx) => {
        const { text, href, className, iconUrl } = link;

        return (
          <li className="nav-item" key={text}>
            <a
              href={href}
              className={`${className} ${idx === 0 ? 'pe-xl-4' : 'px-xl-4'}`}
            >
              {iconUrl && <img src={iconUrl} alt={text} className="me-1" />}
              {text}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

const HorizontalNav: React.FC<HorizontalNavProps> = props => {
  const windowSize = useWindowSize();

  return windowSize.width && windowSize.width < 960 ? (
    <MobileLinkList {...props} />
  ) : (
    <DesktopLinkList links={props.links} />
  );
};

export default HorizontalNav;
