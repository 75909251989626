import { Document } from '@contentful/rich-text-types';
import React from 'react';
import RichTextContent, { RenderOptions } from './RichTextContent';
import useContentful from '../../js/contentful/useContentful';

interface EntryWithContent {
  content: Document;
}

export type { RenderOptions };

const RichTextEntry: React.FC<{
  readonly contentType: string;
  readonly query: Record<string, string>;
  readonly renderOptions?: RenderOptions;
}> = ({ contentType, query, renderOptions = {} }) => {
  const { data } = useContentful<EntryWithContent>({
    contentType,
    query: {
      order: 'sys.createdAt',
      limit: 1,
      ...query
    }
  });

  const entry = data?.items[0];
  const { content } = entry?.fields ?? {};

  return content ? (
    <RichTextContent content={content} renderOptions={renderOptions} />
  ) : null;
};

export default RichTextEntry;
