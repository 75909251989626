import { Entry, EntryCollection } from 'contentful';
import resolveResponse from 'contentful-resolve-response';
import React from 'react';
import { IResourceFields } from '../../types/contentful';

const ServerRenderedSEO: React.FC<{
  // This type is extremely forgiving, since this data comes from the backend.
  // We have extra defensiveness built into this component to guard against
  // missing data, which is why we allow this to be a partial of the entry collection,
  // or null
  readonly entryCollection: Partial<EntryCollection<IResourceFields>> | null;
}> = ({ entryCollection }) => {
  if (!entryCollection?.items) {
    return null;
  }

  const resourceCollection = resolveResponse(entryCollection, {
    removeUnresolved: true
  }) as Array<Entry<IResourceFields>>;
  const resource = resourceCollection.at(0);

  if (!resource) {
    return null;
  }

  const createdAt = new Date(resource.sys.createdAt);
  const publishedAt = new Date(resource.fields.publishedAt ?? createdAt);
  const { title, heroImage, keywords, excerpt } = resource.fields;

  const pageTitle = `Megajobs - ${title}`;
  const updatedAt = new Date(resource.sys.updatedAt);

  // CC-1721: Types generated from contentful say that hero image is a required
  // property but if the customer unpublishes the image the field can be unresolveable.
  // this means the heroImage variable we get from resource.fields may in fact NOT
  // be of type heroImage since its coming in from an outside system.
  // So we cast heroImage to heroImage just in case its not already a heroImage
  // so we can either get the url properly or just return ''
  const heroImageUrl = (
    (heroImage as Partial<typeof heroImage> | undefined)?.fields?.file.url ?? ''
  ).replace(/^\/\//u, 'https://');

  const metas: Record<string, string | undefined> = {
    'og:title': pageTitle,
    'twitter:title': pageTitle,
    'twitter:description': excerpt,
    'description': excerpt,
    'og:description': excerpt,
    'og:image': heroImageUrl,
    'keywords': keywords?.toString(),
    'twitter:image:src': heroImageUrl,
    'DCTERMS.created': publishedAt.toISOString(),
    'DCTERMS.modified': updatedAt.toISOString()
  };

  return (
    <>
      <title>{pageTitle}</title>
      {Object.keys(metas).map(metaKey => (
        <meta key={metaKey} name={metaKey} content={metas[metaKey]} />
      ))}
    </>
  );
};

export default ServerRenderedSEO;
