import { Controller } from '@hotwired/stimulus';

/**
 * This controller adds a CSS class to an element or elements when the action is triggered.
 * It is intentionally very generic, and doesn't prescribe what event should occur for the
 * action to be triggered. Instead, add a data-action attribute to your element to determine
 * when to add the class. The class name can be specified with data-add-class-class-name-value.
 *
 * Usage example:
 *
 * <div data-controller="add-class" data-add-class-class-name-value="test-class">
 *   <button id="button" data-action="add-class#add">Click to add class!</button>
 *   <div data-add-class-target="classRecipient" id="el1"></div>
 *   <div data-add-class-target="classRecipient" id="el2"></div>
 * </div>
 */
export default class AddClassController extends Controller<HTMLElement> {
  private declare readonly classNameValue: string;
  private declare readonly classRecipientTargets: HTMLElement[];

  public static values = { className: String };
  public static targets = ['classRecipient'];

  public add(): void {
    this.classRecipientTargets.forEach(target =>
      target.classList.add(this.classNameValue)
    );
  }
}
