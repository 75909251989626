import React, { useRef, useState } from 'react';
import ReactSelectInterface from 'react-select/dist/declarations/src/Select';
import CustomSelect from './CustomSelect';
import CheckboxMultiSelect from './multiselect/CheckboxMultiSelect';

interface ParentAndChildrenSelectProps {
  readonly formName: string;
  readonly formId: string;
  readonly parentFieldName: string;
  readonly childrenFieldName: string;
  readonly currentParentValue?: string;
  readonly currentChildrenValue?: string[];
  readonly parentOptions: CustomSelectOptions;
  readonly childrenOptions: SelectOptionsWithParents;
  readonly parentPlaceholder: string;
  readonly childrenPlaceholder: string;
  readonly parentLabel: string;
  readonly childrenLabel: string;
  readonly parentRequired?: boolean;
  readonly parentHasError: boolean;
  readonly parentError: string;
  readonly childrenHasError: boolean;
  readonly childrenError: string;
  readonly includeParentPlaceholderOption?: boolean;
}

const ParentAndChildrenSelect: React.FC<ParentAndChildrenSelectProps> = ({
  formName,
  formId,
  parentFieldName,
  childrenFieldName,
  currentParentValue,
  currentChildrenValue,
  parentOptions,
  childrenOptions,
  parentPlaceholder,
  childrenPlaceholder,
  parentLabel,
  childrenLabel,
  parentHasError,
  parentError,
  childrenHasError,
  childrenError,
  parentRequired,
  includeParentPlaceholderOption
}) => {
  const [currentParent, setCurrentParent] = useState<CustomSelectOption>();
  const [currentChildrenOptions, setChildrenOptions] =
    useState(childrenOptions);

  const childrenInputRef = useRef<ReactSelectInterface>(null);

  const onCurrentParentChange = (selected: {
    label: string;
    value: string;
  }) => {
    const filteredChildren = childrenOptions.filter(
      children => children.parent_id === Number(selected.value)
    );

    setChildrenOptions(filteredChildren);
    setCurrentParent(selected);

    if (childrenInputRef.current) {
      childrenInputRef.current.clearValue();
    }
  };

  return (
    <>
      <div className="mb-3">
        <CustomSelect
          controlledValue={currentParent}
          id={`${formId}_${parentFieldName}`}
          initialValue={currentParentValue}
          required={parentRequired}
          hasError={parentHasError}
          label={parentLabel}
          name={`${formName}[${parentFieldName}][]`}
          onChange={onCurrentParentChange}
          options={parentOptions}
          placeholder={parentPlaceholder}
          includePlaceholderOption={includeParentPlaceholderOption}
        />
        {parentHasError ? <span className="is-invalid" /> : null}
        {parentError && (
          <div className="invalid-feedback d-block">{parentError}</div>
        )}
      </div>
      <div className="mb-3">
        <CheckboxMultiSelect
          closeMenuOnSelect
          customRef={childrenInputRef}
          hasError={childrenHasError}
          id={`${formId}_${childrenFieldName}`}
          initialValue={currentChildrenValue}
          isDisabled={!(currentParent ?? currentParentValue)}
          label={childrenLabel}
          name={`${formName}[${childrenFieldName}][]`}
          options={currentChildrenOptions}
          placeholder={childrenPlaceholder}
          showSearchComponent={false}
        />
        {childrenHasError ? <span className="is-invalid" /> : null}
        {childrenError && (
          <div className="invalid-feedback d-block">{childrenError}</div>
        )}
      </div>
    </>
  );
};

export default ParentAndChildrenSelect;
