import { Controller } from '@hotwired/stimulus';

export default class VisibilityWhenChangedController extends Controller {
  public static targets = ['input', 'element'];

  private declare readonly inputTarget: HTMLInputElement;
  private declare readonly elementTargets: HTMLElement[];

  public connect(): void {
    this.inputTarget.addEventListener('input', this._checkVisibility);
  }

  public disconnect(): void {
    this.inputTarget.removeEventListener('input', this._checkVisibility);
  }

  private readonly _checkVisibility = (): void => {
    const newValue = this.inputTarget.value;
    const oldValue = this.inputTarget.defaultValue;
    const isVisible = newValue !== oldValue;

    this.elementTargets.forEach(el => (el.hidden = !isVisible));
  };
}
