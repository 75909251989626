import { Controller } from '@hotwired/stimulus';
import {
  Appearance,
  CssFontSource,
  Stripe,
  StripeElements,
  StripePaymentElement,
  loadStripe
} from '@stripe/stripe-js';

const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    fontSizeBase: '16px',
    fontFamily: 'Raleway, sans-serif'
  },
  rules: {
    '.Label': {
      fontWeight: 'bold',
      fontSize: '18px'
    },
    '.Input': {
      fontSize: '1rem',
      padding: '0.75rem',
      lineHeight: '1.5',
      border: '0.125rem solid #d1d2d3',
      borderRadius: '0.6875rem'
    },
    '.Input:focus': {
      borderColor: '#000',
      boxShadow: 'none'
    }
  }
};

// Loading fonts from our own asset bundle doesn't appear to work.
// There are no CSP warnings, but something in Stripe is blocking the request,
// I think.
const fonts: CssFontSource[] = [
  { cssSrc: 'https://fonts.googleapis.com/css?family=Raleway:400,700' }
];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const stripePublishableKey = process.env.STRIPE_PUBLISHABLE_KEY!;

export default class StripePaymentIntentController extends Controller<HTMLFormElement> {
  public static targets = ['paymentElement', 'errors'];
  public static values = { clientSecret: String, returnPath: String };
  private declare stripe: Stripe;
  private declare elements: StripeElements;
  private declare paymentElement: StripePaymentElement;
  private declare readonly errorsTarget: HTMLElement;
  private declare readonly paymentElementTarget: HTMLElement;
  private declare readonly clientSecretValue: string;
  private declare readonly returnPathValue: string;

  public async connect(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.stripe = (await loadStripe(stripePublishableKey))!;
    this.elements = this.stripe.elements({
      appearance,
      fonts,
      clientSecret: this.clientSecretValue
    });

    this.paymentElement = this.elements.create('payment', {
      terms: { card: 'never' }
    });
    this.paymentElement.mount(this.paymentElementTarget);
  }

  public async submit(event: Event): Promise<void> {
    event.preventDefault();
    this._setFormEnabled(false);
    this._setError(null);

    try {
      const result = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: window.location.origin + this.returnPathValue
        }
      });

      this._setError(
        // validation errors appear on the form by default
        // so we don't want to display the error twice
        result.error.type === 'validation_error' ? null : result.error.message
      );
    } finally {
      this._setFormEnabled(true);
    }
  }

  private _setError(error: string | null | undefined) {
    this.errorsTarget.innerText = error ?? '';
  }

  private _setFormEnabled(enabled: boolean): void {
    const elements = Array.from(this.element.elements) as [HTMLFormElement];

    elements.forEach(el => (el.disabled = !enabled));
  }
}
