import React, { useState } from 'react';
import CustomSelect from './CustomSelect';

interface EndDateProps {
  readonly monthOptions: CustomSelectOptions;
  readonly yearOptions: CustomSelectOptions;
  readonly hasMonthError: boolean;
  readonly hasYearError: boolean;
  readonly monthDefaultValue?: string;
  readonly yearDefaultValue?: string;
  readonly formName: string;
  readonly formId: string;
  readonly currentCheckboxValue: string;
  readonly endedError?: string[];
}

const EndDate: React.FC<EndDateProps> = ({
  monthOptions,
  yearOptions,
  hasMonthError,
  hasYearError,
  monthDefaultValue,
  yearDefaultValue,
  formName,
  formId,
  currentCheckboxValue,
  endedError
}) => {
  const [current, setCurrent] = useState(!!currentCheckboxValue);

  const onCurrentChange = () => {
    setCurrent(!current);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <label className="form-label" htmlFor={`${formName}[ended_at]`}>
          Ended
        </label>
        <div className="form-check">
          <input
            className="me-1 form-check-input"
            type="checkbox"
            checked={current}
            onChange={onCurrentChange}
            name={`${formName}[current]`}
            id={`${formId}_current`}
          />
          <label className="form-check-label" htmlFor={`${formId}_current`}>
            Current
          </label>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 g-3">
        <div className="col">
          <CustomSelect
            name={`${formName}[ended_at][month]`}
            id={`${formId}_ended_at_month`}
            initialValue={monthDefaultValue}
            options={monthOptions}
            placeholder="Month"
            hasError={hasMonthError}
            isDisabled={current}
          />
        </div>
        <div className="col">
          <CustomSelect
            name={`${formName}[ended_at][year]`}
            id={`${formId}_ended_at_year`}
            initialValue={yearDefaultValue}
            options={yearOptions}
            placeholder="Year"
            hasError={hasYearError}
            isDisabled={current}
          />
        </div>
      </div>
      {endedError && !current && (
        <div className="invalid-feedback d-block">{endedError}</div>
      )}
    </>
  );
};

export default EndDate;
