import { Entry } from 'contentful';
import embed from 'embed-video';
import { useEffect, useMemo } from 'preact/hooks';
import React from 'react';
import useContentful from '../../js/contentful/useContentful';
import { IResourceFields } from '../../types/contentful';
import RichTextContent from '../contentful/RichTextContent';

const VideoResource: React.FC<{
  readonly title: string;
  readonly videoEmbedUrl: string;
  readonly publishedAt: Date;
}> = ({ title, videoEmbedUrl, publishedAt }) => {
  const videoEmbedHtml = videoEmbedUrl && embed(videoEmbedUrl);

  return (
    <>
      <h1 className="h2">{title}</h1>
      <div className="d-flex justify-content-between border-bottom border-2 pb-2 mb-3">
        <small>
          Published{' '}
          {publishedAt.toLocaleDateString('en-NZ', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}
        </small>
      </div>
      {videoEmbedHtml && (
        <div
          className="contentful-video-embed ratio ratio-16x9 my-5 w-100"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: videoEmbedHtml }}
        />
      )}
    </>
  );
};

// CCSD-3400
// the image MUST be at least 1200x627 for sharing on social media (linkedin), which is 1.91:1 aspect ratio.
// We want our image to be at least 1400 wide, and if the source images aspect ratio is wider the 1.91:1 (like 2.24:1)
// then we get an image thats too short to fit on social media
// So, we need to ensure its at least 627 high (for linkedin) and at least 1400 wide (for this site)
// and we fit by filling in the extra space with transparency.

const WrittenResourceHeader: React.FC<{
  readonly title: string;
  readonly audience?: string;
  readonly heroImageUrl?: string;
  readonly heroImageTitle?: string;
  readonly publishedAt: Date;
}> = ({ publishedAt, heroImageUrl, heroImageTitle, audience, title }) => {
  return (
    <>
      <div
        className={`banner-image mb-4 theme-${
          audience ? audience.toLowerCase().replace(/[^a-z*]/gu, '') : 'both'
        }`}
      >
        <img
          src={`${heroImageUrl ? heroImageUrl : ''}?w=1400&h=627&fit=pad`}
          alt={heroImageTitle}
          className="w-100"
        />
      </div>
      <h1 className="h2">{title}</h1>
      <div className="d-flex justify-content-between border-bottom border-2 pb-2 mb-3">
        <small>
          Published{' '}
          {publishedAt.toLocaleDateString('en-NZ', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}
        </small>
      </div>
    </>
  );
};

const ShowResource: React.FC<{ readonly resource: Entry<IResourceFields> }> = ({
  resource
}) => {
  const {
    title,
    audience,
    heroImage,
    content,
    summary,
    videoEmbedUrl,
    excerpt,
    keywords
  } = resource.fields;

  const heroImageUrl = heroImage.fields.file.url;
  const heroImageTitle = heroImage.fields.title;

  const updatedAt = useMemo(
    () => new Date(resource.sys.updatedAt),
    [resource.sys.updatedAt]
  );
  const publishedAt = useMemo(() => {
    const createdAt = new Date(resource.sys.createdAt);

    return new Date(resource.fields.publishedAt ?? createdAt);
  }, [resource.fields.publishedAt, resource.sys.createdAt]);

  useEffect(() => {
    document.title = `${document.title}: ${title}`;
    const metas: Record<string, string | undefined> = {
      'og:title': title,
      'twitter:title': title,
      'twitter:description': excerpt,
      'description': excerpt,
      'og:description': excerpt,
      'og:image': heroImageUrl,
      'keywords': keywords?.toString(),
      'twitter:image:src': heroImageUrl,
      'DCTERMS.created': publishedAt.toISOString(),
      'DCTERMS.modified': updatedAt.toISOString()
    };

    Object.keys(metas).forEach(metaKey => {
      const metaTag = document.head.querySelector<HTMLMetaElement>(
        `meta[name='${metaKey}']`
      );
      const metaValue = metas[metaKey];

      return metaTag && metaValue && (metaTag.content = metaValue);
    });
  }, [
    heroImageUrl,
    publishedAt,
    resource,
    summary,
    title,
    updatedAt,
    excerpt,
    keywords
  ]);

  return (
    <>
      {videoEmbedUrl ? (
        <VideoResource
          publishedAt={publishedAt}
          title={title}
          videoEmbedUrl={videoEmbedUrl}
        />
      ) : (
        <WrittenResourceHeader
          heroImageUrl={heroImageUrl}
          heroImageTitle={heroImageTitle}
          publishedAt={publishedAt}
          audience={audience}
          title={title}
        />
      )}

      {content && (
        <section data-testid="content" className="mt-2 mb-5">
          <RichTextContent content={content} />
        </section>
      )}
    </>
  );
};

const Show: React.FC<{ readonly slug: string }> = ({ slug }) => {
  const { fetched, data } = useContentful<IResourceFields>({
    contentType: 'resource',
    query: {
      'fields.slug': slug
    }
  });

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    window.location.pathname = '/404';

    return null;
  }

  const [resource] = data.items;

  return <ShowResource resource={resource} />;
};

export default Show;
export { ShowResource };
