import uniqBy from 'lodash-es/uniqBy';
import { ComponentType } from 'preact';
import { GroupBase, MultiValueProps } from 'react-select';
import { CustomSelectProps } from './NestedOption';

// Interface 'CustomMultiValueProps' incorrectly extends interface 'MultiValueProps<NestedMultiSelectOptionInterface, boolean, GroupBase<NestedMultiSelectOptionInterface>>'.
//   The types of 'selectProps.backspaceRemovesValue' are incompatible between these types.
//     Type 'boolean | undefined' is not assignable to type 'boolean'.
//       Type 'undefined' is not assignable to type 'boolean'.
// @ts-expect-error TS have tried to unpick the type web unsuccessfully
interface CustomMultiValueProps
  extends MultiValueProps<NestedMultiSelectOptionInterface> {
  readonly selectProps: CustomSelectProps;
}
const MultiValueContainer = (
  props: CustomMultiValueProps
):
  | ComponentType<
      MultiValueProps<
        NestedMultiSelectOptionInterface,
        true,
        GroupBase<NestedMultiSelectOptionInterface>
      >
    >
  | undefined => {
  const { index, selectProps, getValue } = props;

  const { normalizedOptions, id } = selectProps;
  const selectedValues = getValue();

  if (index > 0) {
    return undefined;
  }

  const labelForSingleParentCategory = () => {
    const firstCategoryParent = selectedValues.find(
      val => val.parentId === null
    );

    const multipleValues = selectedValues.length > 1;

    const labelFromChild = multipleValues
      ? normalizedOptions.find(val => val.value === selectedValues[0]?.parentId)
          ?.label
      : selectedValues[0]?.label;

    return firstCategoryParent ? firstCategoryParent.label : labelFromChild;
  };

  const multipleParents =
    selectedValues.filter(sel => sel.parentId === null).length > 1 ||
    uniqBy(selectedValues, 'parentId').length > 1;

  const childrenToRender = [];
  const pluralizedLabel = id[0].toUpperCase() + id.substring(1);

  if (multipleParents) {
    childrenToRender.push(<span>Multiple {pluralizedLabel}</span>);
  } else {
    if (selectedValues.length > 1) {
      childrenToRender.push(<span>({selectedValues.length})&nbsp;</span>);
    }

    if (selectedValues.length > 0) {
      childrenToRender.push(labelForSingleParentCategory());
    }
  }

  // Type 'Element' is not assignable to type
  // 'ComponentType<MultiValueProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>> | undefined'.
  // Type 'ReactElement<any, any>' is not assignable to type
  // 'FunctionComponent<MultiValueProps<NestedMultiSelectOptionInterface, true, GroupBase<NestedMultiSelectOptionInterface>>>'
  // @ts-expect-error TS have tried to unpick the type web unsuccessfully
  return <span className="text-truncate mw-85">{childrenToRender}</span>;
};

export default MultiValueContainer;
