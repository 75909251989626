import { Controller } from '@hotwired/stimulus';

// https://mentalized.net/journal/2019/04/26/zendesk-web-widget-turbolinks/

// zendesk won't reinitialize if it thinks it already has
// that includes not reinitializing even across turbo page loads
// but it only knows if it's initialized by the presence of window.zEACLoaded
// so let's mislead it

export default class ZendeskChatWidgetController extends Controller<HTMLElement> {
  public disconnect(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window as any).zEACLoaded = undefined;
  }
}
