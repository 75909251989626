import React, { useRef, useState } from 'react';
import ReactSelectInterface from 'react-select/dist/declarations/src/Select';
import CustomSelect from '../CustomSelect';

interface JobCategoriesProps {
  readonly formName: string;
  readonly formId: string;
  readonly currentCategoryValue?: number | string;
  readonly currentSubCategoryValue?: number | string;
  readonly categoryOptions: CustomSelectOptions;
  readonly subCategoryOptions: SelectOptionsWithParents;
  readonly categoryFieldHasError: boolean;
  readonly categoryFieldError: string;
  readonly subCategoryFieldHasError: boolean;
  readonly subCategoryFieldError: string;
  readonly categoryFieldPlaceholder: string;
  readonly subCategoryFieldPlaceholder: string;
}

const JobCategories: React.FC<JobCategoriesProps> = ({
  formName,
  formId,
  currentCategoryValue,
  currentSubCategoryValue,
  categoryOptions,
  subCategoryOptions,
  categoryFieldHasError,
  categoryFieldError,
  subCategoryFieldHasError,
  subCategoryFieldError,
  categoryFieldPlaceholder,
  subCategoryFieldPlaceholder
}) => {
  const [currentCategory, setCurrentCategory] = useState(currentCategoryValue);
  const [currentSubCategoryOptions, setSubCategoryOptions] = useState(
    subCategoryOptions.filter(
      category => Number(category.parent_id) === Number(currentCategoryValue)
    )
  );
  const subCategoryInputRef = useRef<ReactSelectInterface>(null);
  const onCurrentCategoryChange = (selected: {
    label: string;
    value: number | string;
  }) => {
    const filteredSubCategories = subCategoryOptions.filter(
      category => Number(category.parent_id) === Number(selected.value)
    );

    setSubCategoryOptions(filteredSubCategories);
    setCurrentCategory(selected.value);

    if (subCategoryInputRef.current) {
      subCategoryInputRef.current.clearValue();
    }
  };

  return (
    <>
      <div className="mb-3">
        <CustomSelect
          label="Job Category"
          options={categoryOptions}
          id={`${formId}_category_id`}
          hasError={categoryFieldHasError}
          name={`${formName}[category_id]`}
          placeholder={categoryFieldPlaceholder}
          onChange={onCurrentCategoryChange}
          initialValue={currentCategory}
          required
        />
        {categoryFieldHasError ? <span className="is-invalid" /> : null}
        {categoryFieldError && (
          <div className="invalid-feedback d-block">{categoryFieldError}</div>
        )}
      </div>
      <div className="mb-3">
        <CustomSelect
          label="Sub Category"
          options={currentSubCategoryOptions}
          id={`${formId}_sub_category_id`}
          hasError={categoryFieldHasError}
          name={`${formName}[sub_category_id]`}
          placeholder={subCategoryFieldPlaceholder}
          isDisabled={!currentCategory}
          initialValue={currentSubCategoryValue}
          customRef={subCategoryInputRef}
          required
        />
        {subCategoryFieldHasError ? <span className="is-invalid" /> : null}
        {subCategoryFieldError && (
          <div className="invalid-feedback d-block">
            {subCategoryFieldError}
          </div>
        )}
      </div>
    </>
  );
};

export default JobCategories;
