import { components } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { SingleValueProps } from 'react-select/dist/declarations/src/components/SingleValue';
import CustomSelect from './CustomSelect';

const styles = {
  placeholder: (base: object) => ({
    ...base,
    color: '#1b2224'
  }),
  option: (base: object, data: { isFocused: boolean }): object => ({
    ...base,
    backgroundColor: data.isFocused ? '#DEEBFF' : 'transparent',
    color: '#1b2224'
  }),
  valueContainer: (base: object) => ({
    ...base,
    // this as extra padding because it is not searchable
    // otherwise searchable and non-searchable elements have different heights
    // on searchable selects the Input component creates extra space
    padding: '.75rem 1rem',
    display: 'flex'
  })
};

interface RadioSelectProps {
  className?: string;
  id: string;
  isDisabled?: boolean;
  hasError: boolean;
  label?: string;
  menuIsOpen?: boolean; // for testing
  name: string;
  options: Array<{ label: string }>;
  onChange?: (
    newValue: { label: string; value: number | string },
    meta: { name: string; action: string }
  ) => void;
  placeholder: string;
  initialValue?: string[];
}

const RadioSelect: React.FC<RadioSelectProps> = ({
  placeholder,
  className,
  ...rest
}) => {
  const Option = (props: OptionProps) => {
    const { label, isSelected } = props;

    return (
      <div>
        <components.Option {...props}>
          <input
            id={label}
            type="radio"
            checked={isSelected}
            onChange={() => null}
            className="form-check-input"
          />
          <label htmlFor={label}>&nbsp;{label}</label>
        </components.Option>
      </div>
    );
  };

  const SingleValue = (
    props: SingleValueProps<{ label: string; value: string }>
  ) => {
    const labelToBeDisplayed =
      props.data.label === 'All' ? placeholder : props.data.label;

    return (
      <components.SingleValue {...props}>
        <span>{labelToBeDisplayed}</span>
      </components.SingleValue>
    );
  };

  return (
    <CustomSelect
      {...rest}
      className={`border-0 ${className ?? ''}`}
      components={{
        Option,
        SingleValue
      }}
      hideSelectedOptions={false}
      isClearable={false}
      isSearchable={false}
      placeholder={placeholder}
      styles={styles}
    />
  );
};

export default RadioSelect;
