import BootstrapCarousel from 'bootstrap/js/dist/carousel';
import { Entry } from 'contentful';
import { useEffect } from 'preact/hooks';
import React from 'react';
import { ICallToActionFields, IResourceFields } from '../types/contentful';

const Carousel: React.FC<{
  readonly carouselItems:
    | Array<Array<Entry<ICallToActionFields>>>
    | Array<Array<Entry<IResourceFields>>>
    | CarouselImage[][]
    | Listing[][];
  readonly theme?: 'dark' | 'light';
  readonly carouselId: string;
}> = ({ carouselItems, carouselId, theme = 'dark', children }) => {
  useEffect(() => {
    /**
     * Recreate the carousel when the items change. This is used as a cache-buster
     * when rendering dynamic lists of items. For example, if we display 3 items
     * for large screens, 2 for medium, 1 for small, we can include the window
     * size in the ID. When the ID changes, the carousel instance used by
     * Bootstrap will be recreated. This means that indicators and other
     * carousel state are reset.
     */
    const carouselNode = document.getElementById(`${carouselId}-carousel`);

    if (!carouselNode) {
      return;
    }

    BootstrapCarousel.getInstance(carouselNode)?.dispose();
    BootstrapCarousel.getOrCreateInstance(carouselNode);
  }, [carouselId]);

  return (
    <div
      id={`${carouselId}-carousel`}
      className={[
        'carousel',
        'slide',
        'w-100',
        theme === 'dark' && 'carousel-dark',
        carouselItems.length === 0 && 'd-none'
      ]
        .filter(c => !!c)
        .join(' ')}
      data-bs-interval="false"
    >
      <div
        className="carousel-indicators d-none d-md-flex"
        key={`${carouselId}-indicators`}
      >
        <div className="carousel-indicator">
          <button
            className="carousel-control-prev bg-transparent"
            type="button"
            data-bs-target={`#${carouselId}-carousel`}
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
        </div>

        {carouselItems.map((_carouselSlideItems, idx) => {
          return (
            // because we're not dynamically updating the state to change the array of carousel items,
            // it is ok to use the idx as part of the key in this scenario
            // eslint-disable-next-line react/no-array-index-key
            <div className="carousel-indicator" key={`indicator-for${idx}div`}>
              <button
                type="button"
                // eslint-disable-next-line react/no-array-index-key
                key={`indicator-for${idx}`}
                data-bs-target={`#${carouselId}-carousel`}
                data-bs-slide-to={idx}
                className={`${idx === 0 ? 'active' : ''}`}
                aria-label={`Slide ${idx + 1}`}
              />
            </div>
          );
        })}

        <div className="carousel-indicator">
          <button
            className="carousel-control-next bg-transparent"
            type="button"
            data-bs-target={`#${carouselId}-carousel`}
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="carousel-inner pb-3">
        <button
          className="carousel-control-prev bg-transparent position-absolute start-0 d-md-none"
          type="button"
          data-bs-target={`#${carouselId}-carousel`}
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className="carousel-control-next bg-transparent position-absolute end-0 d-md-none"
          type="button"
          data-bs-target={`#${carouselId}-carousel`}
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>

        {children}
      </div>
    </div>
  );
};

export default Carousel;
