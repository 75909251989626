import { BLOCKS, Block, Inline } from '@contentful/rich-text-types';
import React, { ReactNode } from 'react';
import { options as DefaultRenderOptions } from '../contentful/RichTextContent';
import RichTextEntry from '../contentful/RichTextEntry';

const Introduction: React.FC = () => (
  <RichTextEntry
    contentType="pageIntroduction"
    query={{ 'fields.page': 'CV Builder Introduction' }}
    renderOptions={{
      renderNode: {
        ...DefaultRenderOptions.renderNode,
        [BLOCKS.HEADING_1]: (_node: Block | Inline, children: ReactNode) => (
          <h1 className="h1 w-75">{children}</h1>
        )
      }
    }}
  />
);

export default Introduction;
