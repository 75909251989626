import { useEffect, useState } from 'preact/hooks';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactSelectInterface from 'react-select/dist/declarations/src/Select';

const defaultStyles = {
  container: (base: object) => ({
    ...base,
    padding: 0
  }),
  control: (base: object, state: { isDisabled: boolean }) => ({
    ...base,
    backgroundColor: `${state.isDisabled ? '#d1d2d380' : ''}`,
    transition: 'background-color 300ms',
    border: 'none',
    boxShadow: 'none',
    padding: 0
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  indicatorSeparator: () => ({
    width: 0
  }),
  placeholder: (base: object) => ({
    ...base,
    fontStyle: 'italic',
    color: '#bababa'
  }),
  valueContainer: (base: object) => ({
    ...base,
    padding: '.5rem 1rem'
  }),
  menu: (base: object) => ({
    ...base,
    zIndex: 3
  })
};

interface CustomSelectProps {
  className?: string;
  creatable?: boolean;
  components?: object;
  controlledValue?: CustomSelectOption | CustomSelectOptions | string;
  customRef?: React.RefObject<ReactSelectInterface>;
  hasError?: boolean;
  id: string;
  label?: string;
  labelClassName?: string;
  name: string;
  placeholder?: string;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  initialValue?: number[] | string[] | number | string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  isOptionDisabled?: (option: { label: string; value: string }) => boolean | '';
  isSearchable?: boolean;
  menuIsOpen?: boolean; // for testing
  options: CustomSelectOptions;
  includePlaceholderOption?: boolean; // can be used for a label that shouldn't have a value
  onChange?: (
    newValue: { label: string; value: string },
    meta: { name: string; action: string }
  ) => void;
  required?: boolean;
  styles?: object;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  className,
  creatable,
  components,
  controlledValue,
  customRef,
  hasError,
  id,
  initialValue,
  isDisabled,
  label: fieldLabel,
  labelClassName: fieldLabelClassName,
  onChange,
  options,
  placeholder,
  includePlaceholderOption,
  required,
  styles,
  ...rest
}) => {
  const normalizedOptions = buildNormalizedOptions(
    options,
    initialValue,
    creatable
  );
  const defaultOptions = findDefaultOptions(normalizedOptions, initialValue);

  // This needs to be seperate from normalized/default
  // because those rely on the value prop to support createable
  // whereas the placholderOption exists to provide a label without a value
  const selectOptions = includePlaceholderOption
    ? [{ label: placeholder, value: ' ' }, ...normalizedOptions]
    : normalizedOptions;

  const [requiredInputValue, setRequiredInputValue] = useState<string>(
    initialValue ? 'present' : ''
  );

  // Trigger required field form validations
  useEffect(() => {
    if (!required) {
      return;
    }
    const requiredInput: HTMLInputElement | null = document.querySelector(
      `[data-required-id="${id}"]`
    );

    if (requiredInput && !(requiredInputValue === requiredInput.value)) {
      requiredInput.setAttribute('value', requiredInputValue);
      // need to trigger the listener in `required_fields_controller`
      // requiredInput.blur() _should_ work but doesn't :(
      requiredInput.dispatchEvent(new Event('change'));
    }
  }, [requiredInputValue, id, required]);

  /** React-Select does not emit native change events on the hidden input
   *  field that it creates. This onChange wrapper fixes that by emitting a change
   *  event on the input when the select value changes. If a React-Select onChange
   *  prop is given, it'll then call that with the original args.
   */
  const wrappedOnChange = (
    newValue: { label: string; value: string },
    meta: { action: string; name: string }
  ) => {
    if (meta.action === 'select-option') {
      const input = document.querySelector<HTMLInputElement>(
        `input[name='${meta.name}']`
      );
      const event = new Event('change', { bubbles: true, cancelable: true });

      input?.dispatchEvent(event);
    }

    if (onChange) {
      onChange(newValue, meta);
    }

    if (required) {
      const valueRemoved = Array.isArray(newValue) && newValue.length < 1;

      setRequiredInputValue(valueRemoved ? '' : 'present');
    }
  };

  const selectProps = {
    ...rest,
    className: `form-select ${className ?? ''}${
      hasError && !isDisabled ? 'is-invalid' : ''
    }`,
    components,
    defaultValue: defaultOptions,
    inputId: id,
    isDisabled,
    onChange: wrappedOnChange,
    options: selectOptions,
    placeholder,
    styles: { ...defaultStyles, ...styles },
    ref: customRef,
    value: controlledValue
  };

  const select = (props: object) => {
    return creatable ? (
      <CreatableSelect {...props} />
    ) : (
      <ReactSelect {...props} />
    );
  };

  return (
    <div>
      {fieldLabel && (
        <label
          className={`form-label ${required ? 'required' : ''} ${
            fieldLabelClassName ?? ''
          }`}
          htmlFor={id}
        >
          {fieldLabel}
        </label>
      )}
      {select(selectProps)}
    </div>
  );
};

export default CustomSelect;

const findDefaultOptions = (
  normalizedOptions: CustomSelectOption[],
  initialValue: number[] | string[] | number | string | undefined
) => {
  if (!initialValue) {
    return undefined;
  } else if (Array.isArray(initialValue)) {
    return initialValue.map(val =>
      normalizedOptions.find(opt => opt.value === val.toString())
    );
  }

  return normalizedOptions.find(opt => opt.value === initialValue.toString());
};

const buildNormalizedOptions = (
  options: CustomSelectOptions,
  initialValue: number[] | string[] | number | string | undefined,
  creatable: boolean | undefined
) => {
  const normalizedOptions = options.map(option => {
    const { label, value } = option;

    return {
      label,
      value: value ? value.toString() : label
    };
  });

  /** For createable selects, if users add an option, they will only input a single string
   * if the initialValue string does not match an option name or value, it was created by a user
   * so we display their option using the initialValue string for both the label and the value

   * Note: only supports creatable single select, does not currently handle
   * createable multiselect as we don't have a use case for it
   * however the logic could be adapted in the future if needed
  */
  if (
    creatable &&
    typeof initialValue === 'string' &&
    initialValue.length > 0 &&
    !options.find(opt =>
      /** because we support supplying either name or name+value
       * and normaliz it with the above normalizedOptions method
       * we have to check if the initialValue string matches either
       * the option name or the option value
       */
      opt.value ? opt.value === initialValue : opt.label === initialValue
    )
  ) {
    normalizedOptions.unshift({ label: initialValue, value: initialValue });
  }

  return normalizedOptions;
};
