import { Entry } from 'contentful';
import { useEffect } from 'preact/hooks';
import React from 'react';
import useContentful from '../../js/contentful/useContentful';
import { ICategoryContentFields } from '../../types/contentful';
import RichTextContent from '../contentful/RichTextContent';
import { ResourceList } from '../resources/ResourceList';

const ShowCategory: React.FC<{
  readonly category: Entry<ICategoryContentFields>;
  readonly categoryJobsCount: string;
  readonly categorySearchPath: string;
}> = ({ category, categoryJobsCount, categorySearchPath }) => {
  const {
    categoryName,
    heroImage,
    introduction,
    relatedResources,
    keywords,
    excerpt
  } = category.fields;

  const heroImageUrl = heroImage ? heroImage.fields.file.url : '';
  const heroImageTitle = heroImage ? heroImage.fields.title : '';

  useEffect(() => {
    const createdAt = new Date(category.sys.createdAt);
    const updatedAt = new Date(category.sys.updatedAt);

    document.title = `${document.title}: ${categoryName}`;
    const metas: Record<string, string | undefined> = {
      'og:title': categoryName,
      'twitter:title': categoryName,
      'twitter:description': excerpt,
      'description': excerpt,
      'og:description': excerpt,
      'og:image': heroImageUrl,
      'keywords': keywords?.toString(),
      'twitter:image:src': heroImageUrl,
      'DCTERMS.created': createdAt.toISOString(),
      'DCTERMS.modified': updatedAt.toISOString()
    };

    Object.keys(metas).forEach(metaKey => {
      const metaTag = document.head.querySelector<HTMLMetaElement>(
        `meta[name='${metaKey}']`
      );
      const metaValue = metas[metaKey];

      return metaTag && metaValue && (metaTag.content = metaValue);
    });
  }, [heroImageUrl, category, introduction, categoryName, keywords, excerpt]);

  return (
    <>
      <div className="banner-image banner-image--decorated theme-both mb-4 w-100">
        <img src={heroImageUrl} alt={heroImageTitle} />
      </div>
      <div className="container col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 mb-2 mt-2">
        <h1 className="h2 my-4">{categoryName}</h1>

        {introduction && (
          <section data-testid="introduction" className="mt-2 mb-5">
            <RichTextContent content={introduction} />
          </section>
        )}
        <hr />
        <div className="my-5">
          <h2 className="h3 fs-5 fw-bold text-black">
            We currently have {categoryJobsCount} {categoryName} jobs
          </h2>
          <a
            href={categorySearchPath}
            className="text-secondary fs-5 fw-bold text-decoration-none mb-3"
          >
            View all available jobs
          </a>
        </div>

        <h3 className="fs-5">Related Articles</h3>
        <hr />
        {relatedResources && <ResourceList resources={relatedResources} />}
      </div>
    </>
  );
};

const Show: React.FC<{
  readonly categoryName: string;
  readonly categoryJobsCount: string;
  readonly categorySearchPath: string;
}> = ({ categoryName, categoryJobsCount, categorySearchPath }) => {
  const { fetched, data } = useContentful<ICategoryContentFields>({
    contentType: 'categoryContent',
    query: {
      'fields.categoryName': categoryName
    }
  });

  // Loading state
  if (!fetched) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Not found state
  if (!data?.items.length) {
    window.location.pathname = '/404';

    return null;
  }

  const [category] = data.items;

  return (
    <ShowCategory
      category={category}
      categoryJobsCount={categoryJobsCount}
      categorySearchPath={categorySearchPath}
    />
  );
};

export default Show;
export { ShowCategory };
