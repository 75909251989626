import { Controller } from '@hotwired/stimulus';
import { visit } from '@hotwired/turbo';

interface FetchResponse {
  response: {
    redirected: boolean;
    url: string;
  };
}

const submitDidEnd = ({
  detail
}: CustomEvent<{ success: boolean; fetchResponse?: FetchResponse }>): void => {
  if (detail.success && detail.fetchResponse?.response.redirected) {
    visit(detail.fetchResponse.response.url);
  }
};

// Turbo has an issue with redirects coming from a frame, like a modal.
// It does the redirect, but it filters the response from the redirect so that
// only the submitted turbo frame's content is updated. This is normally good,
// unless your frame is something like a modal where you actually want to update
// the rest of the page.
//
// This controller intercepts form submits, and if the response is successful
// and was a redirect, it prompts Turbo to 'visit' the location using it's cache
// before falling back to another network request.
export default class RedirectTopController extends Controller<HTMLElement> {
  public connect(): void {
    this.element.addEventListener(
      'turbo:submit-end',
      submitDidEnd as EventListener
    );
  }
}
