import chunk from 'lodash-es/chunk';
import React from 'react';
import { useState } from 'react';
import Carousel from './Carousel';
import CustomSelect from './CustomSelect';
import useWindowSize from '../hooks/useWindowSize';

export const FeaturedListings: React.FC<{
  readonly items: Listing[];
  readonly locations: CustomSelectOptions;
  readonly heading: string;
  readonly showRegionsDropdown: boolean;
}> = ({ items, locations, heading, showRegionsDropdown }) => {
  const windowSize = useWindowSize();
  let itemCount = 1;
  const maxItems = 12;

  if (windowSize.width) {
    if (windowSize.width > 960) {
      itemCount = 2;
    }

    if (windowSize.width > 1320) {
      itemCount = 4;
    }
  }

  const [selectedLocation, setSelectedLocation] = useState(' ');
  const [numTimesSelectedLocationChanged, setNumTimesSelectedLocationChanged] =
    useState(0);

  const onLocationChange = (selectedValue: string) => {
    setNumTimesSelectedLocationChanged(numTimesSelectedLocationChanged + 1);
    setSelectedLocation(selectedValue);
  };

  const carouselSlideItems = chunk(
    (selectedLocation === ' '
      ? items
      : items.filter(
          carouselItem => carouselItem.locationId === Number(selectedLocation)
        )
    ).slice(0, maxItems),
    itemCount
  );

  return (
    <div className="d-flex flex-column align-items-start pb-4 bg-white">
      <div className="w-100 d-flex ">
        <h2 className="h2 pe-1 pt-1">{heading}</h2>

        <CustomSelect
          id="location"
          name="location"
          options={locations}
          placeholder="All Regions"
          includePlaceholderOption
          className={`pe-4 border-0 ${showRegionsDropdown ? '' : 'd-none'}`}
          onChange={selected => onLocationChange(selected.value)}
        />
      </div>

      {/* The -version-${numTimesSelectedLocationChanged}-${itemCount} in the carouselId and key attributes */}
      {/* ensure that both the indicators created inside the Carousel component and the carouselSlideItems inside this compenent */}
      {/* rerender whenever either changes - otherwise, if you are on page 3 of the indicators and either the */}
      {/* windows gets smalled or the dropdown changes and there are no longer 3 pages of cards */}
      {/* then you are presented with a page of cards that doesn't exist - you see nothing and the indicators are broken */}
      {/* the Carousel compenent has a comment to that effect as its intended useage */}
      <div className="w-100 align-items-center">
        <Carousel
          carouselItems={carouselSlideItems}
          carouselId={`featured-listings-version-${numTimesSelectedLocationChanged}-${itemCount}`}
        >
          {carouselSlideItems.map((carouselItems, idx) => (
            <div
              key={`${carouselItems[0].title}-slide-group-of-${itemCount}`}
              className={`carousel-item ${idx === 0 ? 'active' : ''}`}
            >
              <div className="d-block m-auto">
                <div className="d-flex justify-content-around w-100">
                  {carouselItems.map(item => {
                    const {
                      title,
                      id,
                      location,
                      summary,
                      logo,
                      expires,
                      saveButtonHtml
                    } = item;

                    return (
                      <div
                        key={`${title}-slide`}
                        data-testid={`${title}-slide`}
                        className="mt-3 mb-5 rounded-3 featured-card"
                      >
                        <div className="d-flex flex-column justify-content-between h-100">
                          <a
                            href={`/listings/${id}`}
                            data-testid={`${title}-link`}
                            className="text-decoration-none flex-shrink-1 d-flex flex-column overflow-visible"
                          >
                            <img
                              className="me-auto"
                              src={logo}
                              height="60"
                              alt={`logo_for_${title}`}
                            />

                            <h2 className="fs-4 fw-bold text-secondary mt-3 mb-1 title-height-limit">
                              <span className="fw-bolder">{title}</span>
                            </h2>
                            <div className="mb-1 fs-7">
                              <span className="fw-bold">{location}</span>
                              <span> | {expires}</span>
                            </div>
                            <div className="mb-1 text-break flex-shrink-1 fs-7">
                              {summary}
                            </div>
                          </a>

                          <div className="d-flex justify-content-end mt-3">
                            <div
                              // Partial rendered by rails so we can turbo-frame replace it
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: saveButtonHtml
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default FeaturedListings;
