import { Controller } from '@hotwired/stimulus';

export default class FieldMatchController extends Controller {
  public static targets = ['newPassword', 'passwordConfirmation'];
  private declare readonly newPasswordTarget: HTMLInputElement;
  private declare readonly passwordConfirmationTarget: HTMLInputElement;

  public connect(): void {
    this.passwordConfirmationTarget.setCustomValidity('does not match');

    this.newPasswordTarget.addEventListener('keyup', this._validatePassword);
    this.newPasswordTarget.addEventListener('blur', this._validatePassword);
    this.passwordConfirmationTarget.addEventListener(
      'keyup',
      this._validatePassword
    );
    this.passwordConfirmationTarget.addEventListener(
      'blur',
      this._validatePassword
    );
  }

  public disconnect(): void {
    this.newPasswordTarget.removeEventListener('keyup', this._validatePassword);
    this.newPasswordTarget.removeEventListener('blur', this._validatePassword);
    this.passwordConfirmationTarget.removeEventListener(
      'keyup',
      this._validatePassword
    );
    this.passwordConfirmationTarget.removeEventListener(
      'blur',
      this._validatePassword
    );
  }

  private readonly _validatePassword = (): void => {
    if (this.passwordConfirmationTarget.value.length < 1) {
      return;
    }

    let passwordsMatch = document.getElementById('passwordsMatch');

    if (!passwordsMatch) {
      passwordsMatch = document.createElement('small');

      passwordsMatch.setAttribute('class', 'form-text text-muted');
      passwordsMatch.setAttribute('id', 'passwordsMatch');
      this.passwordConfirmationTarget.insertAdjacentElement(
        'afterend',
        passwordsMatch
      );
    }

    if (
      this.newPasswordTarget.value === this.passwordConfirmationTarget.value
    ) {
      passwordsMatch.textContent = 'Passwords match';
      this.passwordConfirmationTarget.setCustomValidity('');
    } else {
      passwordsMatch.textContent = 'Passwords do not match';
      this.passwordConfirmationTarget.setCustomValidity('does not match');
    }
  };
}
