const nestedMultiSelectStyles = (label: string): object => ({
  container: (base: object): object => ({
    ...base,
    padding: 0
  }),
  control: (base: object, state: { isDisabled: boolean }): object => ({
    ...base,
    backgroundColor: `${state.isDisabled ? '#d8d8d8' : ''}`,
    transition: 'background-color 300ms',
    border: 'none',
    boxShadow: 'none',
    padding: 0
  }),
  dropdownIndicator: (): object => ({
    display: 'none'
  }),
  indicatorSeparator: (): object => ({
    width: 0
  }),
  group: (base: object): object => ({
    ...base,
    paddingTop: 0
  }),
  groupHeading: (): object => ({
    color: 'black',
    fontWeight: 600,
    fontSize: 16,
    padding: 0
  }),
  menu: (base: object): object => ({
    ...base,
    padding: '1.5rem',
    width: '414px'
  }),
  menuList: (base: object): object => ({
    ...base,
    '&:before': {
      content: JSON.stringify(`Select ${label} from below`),
      fontWeight: 'bold',
      marginBottom: '1rem',
      top: '-0.3rem',
      position: 'relative',
      fontSize: '18px'
    }
  }),
  option: (base: object, data: { isFocused: boolean }): object => ({
    ...base,
    backgroundColor: data.isFocused ? '#DEEBFF' : 'white',
    color: '#1b2224',
    paddingTop: '6px',
    paddingBottom: '6px'
  }),
  placeholder: (base: object): object => ({
    ...base,
    color: '#BABABA',
    fontStyle: 'italic'
  }),
  valueContainer: (base: object): object => ({
    ...base,
    padding: '.5rem 1rem',
    display: 'flex'
  })
});

export default nestedMultiSelectStyles;
